.auth {
  .auth-base {
    &.reset-password {
      .reset-password__wrapped {
        max-width: 450px;
        margin: 0 auto;

        .reset-password__header {
          margin-bottom: 36px;
        }

        .reset-password__content {
          .reset-password__form {
            .form-item {
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }

              .message-login {
                margin-top: 5px;
              }
            }

            .form-func {
              margin-top: 33px;
            }
          }

          .reset-password__question {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media (min-width:992px) {
  .auth {
    .auth-base {
      &.reset-password {
        min-height: 542px;
        padding: 97px 494px 118px 85px;
      }
    }
  }
}