.body-wrapped {
  .wrapped-right {
    .main {
      .section {
        .section-content {
          &.withdraw-content {

            .withdraw-box {
              margin: 20px auto 43px;
              display: table;
              position: relative;
            }

            .withdraw-info {
              margin-bottom: 34px;

              .total-label {
                font-size: 15.2px;
                margin-bottom: 2px;
              }

              .total-price {
                display: table;
              }
            }

            .withdraw-control {
              margin-bottom: 25px;
              margin-left: 33px;

              .control-item {
                margin-right: 71px;
              }
            }

            .withdraw-form {
              margin: 0 -5px;

              .form-item {
                padding: 0 5px;

                .item-label {
                  @include fontSize14-5;
                  @include fontMontserratSemibold;
                }
              }

              .form-half {
                margin-bottom: 18px;
              }
            }

            .withdraw-func {
              margin: 27px 0 0;
            }

            .withdraw-notification {
              margin: 50px 0;
              text-align: center;

              .notification-header {
                margin-bottom: 10px;

                .header-title {
                  @include fontSize14-5;
                  @include fontMontserratSemibold;
                  margin-bottom: 0;
                }
              }

              .notification-content {
                .notification-desc {
                  .text {
                    @include fontSize13-3
                  }
                }

                .notification-icon {
                  margin: 20px 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width:992px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.withdraw-content {
              .withdraw-box {
                margin-top: 58px;
                margin-left: 25px;
              }

              .withdraw-form {
                .form {
                  width: 537px;
                }
              }
            }
          }
        }
      }
    }
  }
}


@media (max-width:479px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.withdraw-content {
              .withdraw-form {
                .form-half {
                  .form-item {
                    margin-bottom: 18px;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}