.auth {
  @include bgImage($bgAuth, no-repeat, cover, center);
  position: relative;
  font-family: daxline;
  min-height: 100vh;

  &:before {
    content: "";
    @include positionTopLeft(absolute, 50%, 50%, translate(-50%, -50%));
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
  }

  .auth-wrapped {
    position: relative;
    max-width: 902px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 95px;
  }

  .back-home {
    display: table;
    margin-bottom: 50px;

    .back-link {
      @include displayFlex(center, flex-start, unset);
      color: $lightGreenColor;
      font-family: montserrat-bold;
      font-size: 17px;
      border-bottom: 1px solid $lightGreenColor;
      text-decoration: none;
      line-height: normal;
    }
  }

  .auth-logo {
    text-align: center;

    .auth-website {
      color: $blackColor;
      font-size: 18px;
      line-height: normal;
      letter-spacing: 5.1px;
      font-family: montserrat-light;
    }
  }

  .auth-base {
    background: $whiteColor;
    border-radius: 5px;
    padding: 20px 15px;
    position: relative;
    box-shadow: 0px 0px 27px 10px rgba(0, 255, 96, .34);

    .base-wrapped {
      position: relative;
    }

    .base-header {
      margin-bottom: 7px;

      .header-title {
        @include title($blackColor, 30px, daxline-bold, 0, 0, uppercase);
      }
    }

    .base-content {
      .base-form {
        margin: 0 -5px;
        position: relative;

        &.register-form {
          .form-reverse {
            display: flex;
            flex-direction: column-reverse;
          }
        }

        .form-box {
          .react-datepicker-wrapper {
            .react-datepicker__input-container {
              input {
                border-color: #dedeed;
              }
            }
          }
        }

        .form-item {
          margin-bottom: 5.6px;
          padding: 0 5px;

          .item-label {
            margin-left: 2px;
            margin-bottom: 2px;
          }

          .item-val {
            border-color: #dedeed;
          }

          .suggestion {
            position: relative;

            .suggestion-wrapped {
              @include positionTopRight(absolute, 50%, 5px, translate(0, -50%));

              &.has-error {
                .suggestion-desc {
                  transform: scale(1);
                  filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5));

                  .text {
                    color: $redColor;
                  }
                }
              }

              .suggestion-icon {
                color: $blueColor;

                &:hover {
                  ~.suggestion-desc {
                    transform: scale(1);
                  }
                }

                i {
                  display: block;
                }
              }

              .suggestion-desc {
                @include positionTopLeft(absolute, 0, 160%, scale(0));
                filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
                min-width: 200px;
                transform-origin: left 10px;
                transition: all .35s ease;

                &:before {
                  content: "";
                  @include positionTopLeft(absolute, 4px, -8px, translate(0, 0));
                  border-top: 5px solid transparent;
                  border-bottom: 5px solid transparent;
                  border-right: 9px solid $whiteColor;
                  z-index: -1;
                }

                .text {
                  @include fontSize13-3;
                  background: $whiteColor;
                  position: relative;
                  padding: 5px;
                  border-radius: 3px;
                }
              }
            }
          }
        }

        .form-term {
          margin-left: 5px;

          .term-tick {
            @include displayFlex(flex-start, flex-start, nowrap);
            margin-top: 5px;

            .confirm-tick {
              background: #cdcde4;
              border-radius: 2px;
              border: 1px solid #b3b3bc;
              margin-top: 2px;
              cursor: pointer;

              &.active {
                i {
                  transform: scale(1);
                }
              }

              i {
                transition: all .35s ease;
                transform: scale(0);
                font-size: 11px;
                display: block;
              }
            }

            .text {
              font-size: 12px;

              a {
                color: $blueColor;
                margin: 0 3px;
                text-decoration: underline;
              }

              span {
                cursor: pointer;

                &:first-child {
                  padding-left: 11px;
                }
              }
            }
          }
        }

        .form-func {
          margin-top: 17px;
          text-align: center;

          .btn-create {
            font-size: 13px;
            padding: 5px 33px;
            text-transform: uppercase;
          }
        }
      }

      .base-question {
        text-align: center;
        font-size: 13.5px;

        &.register-question {
          margin-top: 12px;
        }

        a {
          margin-left: 25px;
          text-decoration: underline;
        }
      }
    }
  }
}

.modal {
  .modal-auth {
    .modal-content {
      .modal-header {
        padding-top: 35px;
        border-bottom: 0;

        .modal-title {
          font-family: daxline;
          font-size: 14.6px;
          text-align: center;
          flex-basis: 100%;
        }
      }

      .modal-body {
        padding-bottom: 24px;

        #otp {
          display: table;
          margin: 0 auto;

          .otp-item {
            @include displayFlex(center, flex-start, nowrap);
            position: relative;

            &:not(:last-child) {
              .otp-resend {
                &:after {
                  content: "";
                  @include positionTopLeft(absolute, 50%, -2px, translate(0, -50%));
                  height: 2px;
                  width: 5px;
                  background: #cdcde4;
                }
              }
            }

            .otp-resend {
              flex-grow: 1;
              margin-left: 10px;
              padding-left: 10px;
              position: relative;

              &.active {
                .btn-resend {
                  color: $blueColor;
                  border-color: $blueColor;
                }
              }

              .btn-resend {
                @include fontSize13-3;
                background-color: transparent;
                color: $blackColor;
                border: none;
                border-bottom: 1px solid $blackColor;
                border-radius: 0;
                padding: 0;
                font-family: daxline;

                &:hover {
                  color: $blueColor;
                  border-color: $blueColor;
                }

                .resend-time {
                  margin-left: 3px;
                }
              }
            }

            .item-val {
              padding: 5px;
              text-align: center;
              width: auto;
            }
          }
        }

        .opt-message {
          font-family: daxline;
          margin-top: 5px;
        }

        .next-step {
          margin-top: 26px;
          text-align: center;
        }
      }
    }
  }

  .modal-otp {
    margin-top: 194px;
  }

  .modal-alert {
    margin-top: 147px;

    .modal-content {
      .modal-body {
        padding-top: 10px;

        .alert-content {
          text-align: center;

          .alert-access {
            margin-top: 35px;
          }
        }
      }
    }
  }
}


@media (min-width:992px) {
  .auth {
    .auth-logo {
      @include positionTopRight(absolute, 158px, 34px, translate(0, 0));

      .auth-website {
        color: $whiteColor;
      }
    }

    .auth-base {
      padding: 36px 460px 29px 52px;
      background: rgba(0, 0, 0, 0.6);

      &:before {
        content: "";
        @include positionTopRight(absolute, 0, 0, translate(0, 0));
        height: 100%;
        width: 100%;
        background: $whiteColor;
        clip-path: polygon(0 0%, 43% 0%, 100% 98%, 100% 100%, 0 100%);
        border-radius: 5px;
      }

      .base-content {
        .base-form {
          .form-box {
            .react-datepicker-wrapper {
              .react-datepicker__input-container {
                input {
                  padding: 3.5px 10px;
                }
              }
            }
          }

          .form-item {
            .item-label {
              @include fontSize13-3;
            }

            .item-val {
              padding: 3.5px 10px;
            }
          }
        }
      }
    }
  }
}

@media (max-width:991px) {
  .auth {
    .auth-base {
      .base-content {
        .base-form {
          .form-item {
            .suggestion {
              .suggestion-wrapped {
                .suggestion-desc {
                  left: unset;
                  right: 0;
                  top: 25px;
                  transform-origin: right 0px;

                  &:before {
                    top: -8px;
                    right: 3px;
                    left: unset;
                    border-top: unset;
                    border-bottom: 9px solid #ffffff;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                  }
                }
              }
            }
          }
        }
      }
    }

    .auth-wrapped {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 50px;
    }

    .auth-logo {
      margin-bottom: 10px;
    }
  }
}