.auth {
  .auth-base {
    &.login {
      .login-wrapped {
        .login-header {
          margin-bottom: 36px;
        }

        .login-content {
          .login-form {
            .form-item {
              margin-bottom: 24px;

              &:last-child {
                margin-bottom: 0;
              }

              .message-login {
                margin-top: 5px;
              }
            }

            .form-func {
              margin-top: 33px;
            }
          }

          .login-question {
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media (min-width:992px) {
  .auth {
    .auth-base {
      &.login {
        padding: 97px 494px 118px 85px;
      }
    }
  }
}