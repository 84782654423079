.form-readonly,
.bank-form {
  margin-top: 15px;

  .form {
    .form-item {
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      .item-label {
        margin-bottom: 1px;
        @include fontSize13-3;
      }

      input,
      .item-val {
        width: 100%;
        padding: 8px 15px;
        @include fontSize13-3;
        border-radius: 3px;
      }
    }
  }
}

.form-readonly {
  .form {
    .form-item {

      input,
      .item-val {
        padding: 8px 15px 8px 33px;
        border: none;
        background: $inputColor;
      }
    }
  }
}

.person-information-form {
  &.form-readonly {
    .form {
      @include displayFlex(normal, space-between, wrap);
    }
  }
}

.account-info {
  .text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .btn-copy {
      color: $whiteColor;
      background: $greenBtnColor;
      font-size: 12px;
      margin-left: 10px;
      padding: 0 16px;
      border-radius: 5px;
      line-height: normal;
      @include fontMontserratSemibold;
    }

    span {
      margin-left: 2px;
    }
  }
}

.body-wrapped {
  .wrapped-right {
    .main {
      .section {
        .section-content {

          &.information-content {
            .information-box {
              margin: 30px 0;

              .contract-information {
                display: none !important;
                visibility: hidden !important;
                opacity: 0 !important;
              }

              .header-title {
                @include fontSize14-5;
                @include fontMontserratSemibold;
                margin-bottom: 0;
              }

              .period,
              .payment,
              .term,
              .detail,
              .person-information {
                display: table;
                position: relative;
              }

              .term,
              .detail,
              .person-information {
                .next-step {
                  text-align: center;
                }
              }

              .term-header,
              .detail-header,
              .person-information-header {
                text-align: center;
              }

              .term-desc,
              .detail-desc,
              .person-information-desc {
                text-align: justify;
              }

              .period {
                .period-header {
                  margin-bottom: 22px;
                }

                .period-content {
                  .period-box {
                    &.no-period {
                      .period-item {
                        .invest-price {
                          max-width: 322px;
                        }
                      }
                    }
                  }

                  .period-item {
                    &.period-control {

                      .control-item {
                        margin-right: 70px;
                      }
                    }

                    &.period-note {
                      margin-bottom: 22px;

                      .note-title {
                        font-size: 15px;
                        margin-bottom: 12px;
                      }

                      .note-desc {
                        @include fontSize13-3;
                      }
                    }

                    &.period-selector {
                      margin-bottom: 15px;

                      .selector-item {
                        margin-right: 13px;
                        width: 100%;
                        min-width: 210px;

                        &:last-child {
                          margin-right: 0;
                        }
                      }
                    }

                    &.period-profit {
                      margin-bottom: 10px;

                      .text {
                        font-size: 13.6px;

                        b {
                          font-size: 15px;
                          padding-left: 2px;
                          margin-right: 2px;
                        }
                      }
                    }

                    .invest-price {
                      .price-label {
                        font-size: 15px;
                        margin-bottom: 14px;
                      }
                    }
                  }
                }

                .next-step {
                  .btn-next {
                    margin-left: 4px;
                  }
                }
              }

              .term {
                .term-header {
                  margin-bottom: 17px;
                }

                .term-content {
                  .term-desc {
                    .text {
                      @include fontSize13-3;
                      word-spacing: 0.7px;
                      line-height: 21px;
                      margin-bottom: 8px;
                    }

                    .term-list {
                      margin-left: 20px;
                      margin-bottom: 10px;

                      .text {
                        margin-bottom: 0px;
                      }
                    }

                    .term-table {
                      border: 1px solid #b5bdd8;
                      width: 100%;
                      margin-bottom: 10px;
                      margin-top: 10px;

                      th,
                      td {
                        border: 1px solid #b5bdd8;
                        padding: 5px;
                        text-align: center;
                      }

                      td {
                        &:nth-child(2) {
                          text-align: left;
                        }
                      }
                    }
                  }

                  .term-confirm {
                    display: table;
                    margin: 20px auto 0;
                    padding-left: 30px;
                    cursor: pointer;
                    position: relative;
                    line-height: normal;

                    &.active {
                      .confirm-tick {
                        i {
                          transform: scale(1);
                        }
                      }
                    }

                    .confirm-tick {
                      @include positionTopLeft(absolute, 50%, 0, translate(0, -50%));
                      width: 14px;
                      height: 14px;
                      background: $confirmTermColor;
                      border-radius: 3px;

                      i {
                        transition: all .35s ease;
                        @include fontSize14;
                        transform: scale(0);
                      }
                    }

                    .text {
                      @include fontSize13-3;
                    }
                  }
                }
              }

              .payment {
                .payment-header {
                  margin-bottom: 20px;

                  .header-title {
                    margin-bottom: 27px;
                  }

                  .header-desc {
                    @include fontSize14-5;
                    margin-left: 6px;
                  }
                }

                .payment-content {

                  .payment-box {
                    margin-bottom: 25px;
                  }

                  .payment-control {
                    margin-left: 18px;
                  }

                  .payment-account {
                    margin-left: 5px;
                    margin-top: 23px;

                    .account-selector {
                      margin-bottom: 25px;
                      max-width: 207px;

                      .account-label {
                        font-size: 14.7px;
                        margin-bottom: 7px;
                      }
                    }

                    .account-info {
                      .text {
                        font-size: 14.6px;
                        margin-bottom: 11px;

                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }

                .next-step {
                  margin-left: 4px;
                }
              }

              .detail {
                .detail-header {
                  margin-bottom: 25px;
                }

                .detail-content {
                  .detail-desc {
                    .text {
                      @include fontSize13-3;
                      line-height: 21px;

                      .text-color {
                        margin-left: 4px;
                      }
                    }

                    .detail-list {
                      margin-top: 9px;
                      margin-bottom: 17px;

                      .indent-text {
                        margin-left: 37px;
                        font-family: montserrat-medium-italic;
                        @include fontSize13-3;
                        line-height: 21px;
                      }
                    }

                    .detail-func {
                      @include displayFlex(center, center, wrap);
                      margin: 5px 0;

                      .func-item {
                        margin: 5px 6.5px;

                        .btn {
                          @include fontSize13-3;
                          padding: 5px 10px;
                          border-radius: 3px;
                          color: $whiteColor;
                          min-width: 119px;
                        }
                      }
                    }
                  }
                }
              }

              .person-information {
                .person-information-header {
                  padding-right: 38px;
                  margin-bottom: 25px;
                }

                .person-information-content {
                  .person-information-desc {
                    .text {
                      @include fontSize13-3;
                      line-height: 21px;
                    }
                  }
                }
              }

              .next-step {
                margin-top: 18px;
              }
            }

            .history-invest {
              .history-header {
                .header-title {
                  @include title(#2f3c5b, 16.5px, montserrat-semibold, 0 0 0 25px, 0, uppercase);
                  text-decoration: underline;
                }
              }

              .history-content {
                .history-box {
                  margin-top: 37px;

                  .table {
                    tbody {
                      tr {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.modal {
  .modal-border-top {
    .modal-content {
      @include modalHasBorderTop;
    }
  }

  .modal-contract-detail {
    .modal-content {
      .modal-body {
        .contract-detail {
          .detail-header {
            .header-title {
              text-align: center;
              font-size: 22px;
              @include fontMontserratSemibold;
              margin: 20px 0;
            }
          }

          .detail-content {
            .detail-item {
              margin-bottom: 40px;

              &:last-child {
                margin-bottom: 0;
              }

              &.view-pdf {
                @include displayFlex(center, center, wrap);

                .view-item {
                  margin: 0 5px;
                }
              }

              .item-header {
                .header-title {
                  @include fontSize16;
                  @include fontMontserratSemibold;
                  margin-bottom: 20px;
                }
              }

              .item-content {
                .item-info {
                  width: 100%;
                  max-width: 545px;
                  margin: 0 auto;

                  .account-info {
                    .text {
                      margin-bottom: 11px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .modal-footer {
        margin-bottom: 20px;
      }
    }
  }

  .modal-notification {
    text-align: center;

    .modal-content {
      .modal-body {
        .notification {
          .notification-header {
            .header-title {
              @include fontSize16;
              @include fontMontserratSemibold;
              margin-top: 20px;
            }

            .text {
              font-size: 15px;
              margin-bottom: 10px;
            }
          }

          .notification-content {
            .notification-icon {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .modal-preview-contract {
    max-width: 960px;

    .modal-content {
      .modal-header {
        border: none;
        padding-top: 35px;

        .modal-title {
          flex-basis: 100%;
          text-align: center;
          @include fontMontserratSemibold;
          text-transform: uppercase;
        }

        .close {
          @include positionTopRight(absolute, 15px, 15px, translate(0, 0));
        }
      }

      .modal-body {
        .preview {
          margin: 15px 30px;
          overflow-wrap: break-word;

          .preview-header {
            text-align: center;
            @include fontMontserratSemibold;
            margin-bottom: 20px;

            .header-title {
              font-size: 24px;
            }
          }

          .preview-content {
            i {
              display: inline;
            }

            ol {
              margin: 0;
              padding: 0;
              counter-reset: item;

              >li {
                margin: 0;
                padding: 0 0 0 2em;
                text-indent: -2em;
                list-style-type: none;
                counter-increment: item;
                @include fontSize16;
                line-height: 24px;

                &:before {
                  content: counter(item) ".";
                  display: inline-block;
                  min-width: 1em;
                  margin-right: 15px;
                  font-weight: bold;
                  text-align: right;
                }
              }
            }

            .preview-contract {
              .contract-detail {
                .contract-header {
                  text-align: center;
                  margin-bottom: 20px;

                  .header-title {
                    font-size: 22px;
                    @include fontMontserratSemibold;
                    margin-bottom: 0;
                  }
                }

                .contract-content {
                  .desc-text {
                    position: relative;
                    @include fontSize16;
                    line-height: 24px;

                    &.note {
                      margin: 10px 0;
                    }

                    &.text-indent {
                      text-indent: 30px;

                      ul {
                        padding-left: 30px;
                      }
                    }

                    .text-line {
                      @include positionTopLeft(absolute, 0, 0, translate(0, 0));
                      text-indent: 0;
                    }
                  }

                  .contract-law,
                  .contract-date {
                    margin-bottom: 10px;
                  }

                  .contract-info {
                    margin-top: 5px;

                    .contract-item {
                      margin-bottom: 5px;

                      .item-header {
                        margin-bottom: 5px;

                        .item-title {
                          font-weight: bold;
                          text-transform: uppercase;
                          margin-bottom: 0;
                          font-size: 20px;
                        }
                      }

                      .item-content {
                        .item-desc {
                          @include displayFlex(center, flex-start, wrap);
                          @include fontSize16;
                          margin-bottom: 5px;
                        }
                      }
                    }
                  }

                  .contract-term {
                    .term-header {
                      margin-bottom: 10px;

                      .term-set {
                        .set-header {
                          text-align: center;

                          .set-title {
                            font-size: 22px;
                            font-weight: bold;
                          }
                        }

                        .set-content {
                          .set-desc {
                            font-style: italic;
                          }
                        }
                      }
                    }

                    .term-content {
                      .term-item {
                        margin-bottom: 15px;

                        .item-header {
                          .item-title {
                            font-weight: bold;
                            font-size: 20px;
                          }
                        }
                      }
                    }
                  }

                  .contract-sign {
                    .sign-box {
                      display: table;
                      margin: 0 auto;
                      border: 1px solid $grayColor;

                      .sign-item {
                        text-align: center;
                        min-height: 200px;
                        display: inline-block;
                        width: 250px;
                        position: relative;

                        .vertical-line {
                          position: absolute;
                          top: 0px;
                          right: 0px;
                          width: 1px;
                          height: 100%;
                          background: rgb(203, 208, 215);
                        }

                        .item-desc {
                          margin: 5px 0;

                          .desc-text {
                            font-weight: bold;
                            text-transform: uppercase;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .modal-footer {
        .btn {
          @include fontSize13-3;
          padding: 5px 10px;
          border-radius: 3px;
          color: $whiteColor;
          min-width: 119px;
        }
      }
    }
  }

  .modal-confirm-finish {
    .confirm-finish {
      text-align: center;
      @include fontMontserratSemibold;

      .confirm-title {
        font-size: 2em;
        margin: 20px 0;
      }

      .confirm-text {
        font-size: 1.25em;
      }
    }

    .modal-footer {
      padding-bottom: 30px;
    }
  }

  .modal-footer {
    border: none;
    justify-content: center;
  }
}

// Responsive

// min-width

@media (min-width:1300px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.information-content {
              .information-box {
                margin: 50px 0 0 60px;

                .detail {
                  .detail-content {
                    .detail-desc {
                      .detail-account {
                        .account-info {
                          margin-left: 20px;
                        }
                      }
                    }
                  }
                }
              }

              .history-invest {
                margin-top: 66px;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width:992px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.information-content {
              .information-box {
                .person-information {
                  .person-information-content {
                    .person-information-form {
                      margin-top: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal {
    .modal-contract-detail {
      .modal-content {
        .modal-body {
          padding-left: 60px;
          padding-right: 60px;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.information-content {
              .information-box {
                .period {
                  .period-content {
                    margin-left: 13px;

                    .period-item {
                      &.period-selector {
                        @include displayFlex(flex-start, flex-start, nowrap);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width:480px) {
  .person-information-form {
    .form {
      margin: 0 -20px;

      .form-item {
        width: 50%;
        padding: 0 20px;
      }
    }
  }

  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.information-content {
              .information-box {
                .payment {
                  .payment-content {
                    .payment-control {
                      .control-item {
                        margin-right: 43px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal {
    .modal-preview-contract {
      .modal-content {
        .modal-body {
          .preview {
            .preview-content {
              .preview-contract {
                .contract-detail {
                  .contract-content {
                    .contract-info {
                      .contract-item {
                        .item-content {
                          .item-desc {
                            .desc-title {
                              flex-basis: 100%;
                              max-width: 175px;
                              @include displayFlex(center, space-between, nowrap);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// End min-width

// max-width
@media (max-width:1199px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.information-content {
              .information-box {

                .period,
                .payment,
                .term,
                .detail,
                .person-information {
                  margin: 0 auto;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:991px) {
  .modal {
    .modal-preview-contract {
      margin: 0.5rem;

      .modal-content {
        .modal-body {
          .preview {
            margin: 0 !important;
          }
        }
      }
    }
  }
}

@media (max-width:575px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.information-content {
              .information-box {
                .period {
                  .period-content {
                    .period-item {
                      &.period-selector {
                        .selector-item {
                          min-width: unset;
                          margin-bottom: 10px;

                          &:last-child {
                            margin-bottom: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal {
    .modal-preview-contract {
      .modal-content {
        .modal-body {
          .preview {
            .preview-content {
              .preview-contract {
                .contract-detail {
                  .contract-content {
                    .contract-sign {
                      .sign-box {
                        .sign-item {
                          width: 100% !important;

                          .vertical-line {
                            top: unset !important;
                            bottom: 0;
                            width: 100% !important;
                            height: 1px !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:479px) {


  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.information-content {
              .information-box {
                .period {
                  .period-content {
                    .period-item {
                      &.period-control {
                        .control-item {
                          margin-right: 20px;
                        }
                      }
                    }
                  }
                }

                .payment {
                  .payment-content {
                    .payment-control {
                      .control-item {
                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                    }
                  }
                }

                .detail {
                  .detail-content {
                    .detail-desc {
                      .detail-account {
                        .account-info {
                          margin-left: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .modal {
    .modal-contract-detail {
      .modal-content {
        .modal-body {
          .contract-detail {
            .detail-content {
              .detail-item {
                margin-bottom: 15px;

                &.view-pdf {
                  .view-item {
                    margin-top: 5px;
                    margin-bottom: 5px;
                  }
                }

                .item-content {
                  .item-info {
                    .account-info {
                      .text {
                        margin-bottom: 5px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// End max-width

// End Responsive