@keyframes loader {
  0% {
    left: -100px;
  }
  100% {
    left: 110%;
  }
}
@keyframes animate {
  17% {
    border-bottom-right-radius: 3px;
  }
  25% {
    transform: translateY(9px) rotate(22.5deg);
  }
  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }
  75% {
    transform: translateY(9px) rotate(67.5deg);
  }
  100% {
    transform: translateY(0) rotate(90deg);
  }
}
@keyframes shadow {
  50% {
    transform: scale(1.2, 1);
  }
}
.loading {
  background: #69db73;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

#loader {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}

#box {
  width: 50px;
  height: 50px;
  background: #fff;
  animation: animate 0.5s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 3px;
}

#shadow {
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  animation: shadow 0.5s linear infinite;
}

@font-face {
  font-family: digital-italic;
  src: url("/assets/fonts/digital-7-italic.ttf");
}
@font-face {
  font-family: daxline;
  src: url("/assets/fonts/UTM-Dax.ttf");
}
@font-face {
  font-family: daxline-bold;
  src: url("/assets/fonts/UTM-DaxBold.ttf");
}
@font-face {
  font-family: montserrat;
  src: url("/assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: montserrat-semibold;
  src: url("/assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: montserrat-italic;
  src: url("/assets/fonts/Montserrat-Italic.ttf");
}
@font-face {
  font-family: montserrat-medium;
  src: url("/assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: montserrat-bold;
  src: url("/assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: montserrat-medium-italic;
  src: url("/assets/fonts/Montserrat-MediumItalic.ttf");
}
@font-face {
  font-family: montserrat-light;
  src: url("/assets/fonts/Montserrat-Light.ttf");
}
@keyframes loading-animate {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 200%;
  }
}
.loading-animate {
  background: linear-gradient(125.75deg, white 0%, #dee4f0 33%, white 66%, #dee4f0 100%);
  background-size: 200% 100%;
  animation-name: loading-animate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 8px;
  max-width: 400px;
  width: 90vw;
  padding-bottom: 141.453%;
  font-size: 0;
}

@keyframes waiting {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.waiting {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 110%;
  height: 110%;
  background: rgba(202, 202, 202, 0.5);
  border-radius: 5px;
  z-index: 1;
}
.waiting .waiting-wrapped {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.waiting .waiting-wrapped .wait-item {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #6dbe65;
  border-radius: 50%;
  animation: waiting 1.2s linear infinite;
}
.waiting .waiting-wrapped .wait-item:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.waiting .waiting-wrapped .wait-item:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.waiting .waiting-wrapped .wait-item:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.waiting .waiting-wrapped .wait-item:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.waiting .waiting-wrapped .wait-item:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.waiting .waiting-wrapped .wait-item:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.waiting .waiting-wrapped .wait-item:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.waiting .waiting-wrapped .wait-item:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.waiting .waiting-wrapped .wait-item:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.waiting .waiting-wrapped .wait-item:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.waiting .waiting-wrapped .wait-item:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.waiting .waiting-wrapped .wait-item:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

body {
  font-family: montserrat;
  user-select: none;
}

*:hover, *:focus, *:active, *:visited,
button:hover,
button:focus,
button:active,
button:visited,
.btn:hover,
.btn:focus,
.btn:active,
.btn:visited {
  outline: none;
  box-shadow: none;
}
*.btn-copy.disabled,
button.btn-copy.disabled,
.btn.btn-copy.disabled {
  pointer-events: none;
  opacity: 0.65;
}

p {
  margin-bottom: 0;
}

input,
select {
  background: #ffffff;
  border: 1px solid #a8c4e6;
  border-radius: 3px;
  padding: 7px 15px;
  font-size: 13.2px;
  width: 100%;
}
input:disabled,
select:disabled {
  background: #dae0f4;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.error.error-500 {
  min-height: 100vh;
}
.error.error-500 .back-home {
  margin-right: 40px;
}
.error .back-home {
  display: block;
  margin-top: -60px;
}

.red {
  color: #ff0055;
}

.text-color {
  font-size: 13.3px;
}

.btn.orange {
  background: #ec8a0b;
  color: #ffffff;
}
.btn.green {
  background: #00d58b;
  color: #ffffff;
}
.btn.blue {
  background: #00a2ff;
  color: #ffffff;
}
.btn.btn-func {
  background: #0bd08c;
  color: #ffffff;
  border-radius: 50px;
  padding: 5px 50px;
  font-size: 15px;
  box-shadow: 0px 5px 20px 0 rgba(13, 191, 130, 0.55);
}
.btn.btn-func.red {
  background: #e34039;
  box-shadow: 0px 5px 20px 0 rgba(237, 28, 36, 0.55);
}

.item-verify {
  margin-top: 5px;
}

.text-verify {
  display: block;
  line-height: normal;
}
.text-verify .verify-icon {
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  line-height: normal;
  margin-right: 5px;
}
.text-verify .verify-icon i {
  display: block;
  font-size: 16px;
}

.form-half {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.form-half .form-item {
  flex-basis: 50%;
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker-popper select {
  padding: 5px;
}

.price-box {
  padding: 1px;
  border: 1px solid #a8c4e6;
  border-radius: 3px;
}
.price-box .price-number {
  position: relative;
}
.price-box .price-number .price-val {
  padding: 3.5px 60px 3.5px 15px;
  border: none;
  font-size: inherit;
  border-radius: 3px;
  width: 100%;
}
.price-box .price-number .price-type {
  background: #474b67;
  color: #ffffff;
  border-radius: 3px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  height: calc(100% - 1px);
  padding: 5px 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.table-responsive {
  position: relative;
}
.table-responsive .waiting {
  width: 100%;
  height: 100%;
}

.table {
  margin-bottom: 0;
}

.pagi {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}
.pagi .pagi-item {
  display: inline-block;
  margin: 0 2px;
}
.pagi .pagi-item.active .pagi-link {
  background-color: #7c89a8;
  color: #ffffff;
}
.pagi .pagi-item.disable {
  pointer-events: none;
}
.pagi .pagi-item.disable .pagi-link {
  cursor: default;
  pointer-events: none;
}
.pagi .pagi-item.disable .pagi-link:hover {
  background-color: transparent;
  color: #000000;
}
.pagi .pagi-item.pagi-dots .pagi-link {
  cursor: default;
  border: none;
}
.pagi .pagi-item.pagi-dots .pagi-link:hover {
  background: transparent;
  color: #000000;
}
.pagi .pagi-item.pagi-prev {
  margin-right: 40px;
}
.pagi .pagi-item.pagi-next {
  margin-left: 40px;
}
.pagi .pagi-item .pagi-link {
  display: block;
  cursor: pointer;
  padding: 3px 6px;
  font-size: 13px;
  border: 1px solid #7c89a8;
  min-width: 30px;
  text-align: center;
  border-radius: 3px;
}
.pagi .pagi-item .pagi-link:hover {
  background-color: #7c89a8;
  color: #ffffff;
}
.pagi .pagi-item .pagi-link i {
  line-height: unset;
}

.control-tab {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.control-tab .control-item {
  position: relative;
  font-size: 14.5px;
  padding-left: 26px;
  cursor: pointer;
  line-height: normal;
  margin-bottom: 10px;
}
.control-tab .control-item:last-child {
  margin-right: 0 !important;
}
.control-tab .control-item.active .dot:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  background: #00d58b;
  border-radius: 100%;
}
.control-tab .control-item .dot {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 13px;
  height: 13px;
  background: #cbd0d7;
  border-radius: 100%;
}

.body-wrapped {
  display: flex;
  width: 100%;
}
.body-wrapped i {
  display: block;
}
.body-wrapped .wrapped-left {
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  width: 100%;
  background: linear-gradient(to bottom, #18d160 0%, #1da898 100%);
  max-width: 275px;
  overflow-y: auto;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.945, 0.02, 0.27, 0.665);
  z-index: 999;
  box-shadow: 15px 15px 25px 0px rgba(6, 7, 50, 0.3);
}
.body-wrapped .wrapped-left::-webkit-scrollbar {
  width: 0;
}
.body-wrapped .wrapped-left::-webkit-scrollbar-track {
  background: #cdcdcd;
}
.body-wrapped .wrapped-left::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.body-wrapped .wrapped-left::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.body-wrapped .wrapped-left:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: unset;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/bg_sidebar.png");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: -1;
}
.body-wrapped .wrapped-left #sidebar {
  padding: 20px;
  padding-left: 23px;
  padding-right: 25px;
  position: relative;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped {
  margin-bottom: 20px;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-header {
  margin-top: 30px;
  margin-left: 27px;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-header .close-sidebar {
  display: none;
  margin: 5px 5px 5px auto;
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-header .close-sidebar i {
  font-size: 35px;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list {
  margin-top: 40px;
  padding-top: 10px;
  padding-bottom: 70px;
  position: relative;
  overflow: hidden;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list:before, .body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  height: 25px;
  background: rgba(0, 0, 0, 0.2);
  filter: blur(5px);
  border-radius: 100%;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list:before {
  top: -15px;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list:after {
  bottom: -15px;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content {
  padding-left: 0;
  list-style: none;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content .sidebar-item {
  margin-bottom: 8px;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content .sidebar-item .sidebar-link {
  position: relative;
  padding: 18px 10px;
  padding-left: 55px;
  display: block;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  transition: all 0.5s ease;
  z-index: 0;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content .sidebar-item .sidebar-link:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0 inset transparent;
  border-radius: 50px;
  z-index: -1;
  transition: all 0.35s ease;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content .sidebar-item .sidebar-link:hover, .body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content .sidebar-item .sidebar-link.active {
  background: #11bc61;
  border-radius: 50px;
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content .sidebar-item .sidebar-link:hover:after, .body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content .sidebar-item .sidebar-link.active:after {
  box-shadow: 0px 0px 16px 7px inset rgba(25, 27, 47, 0.31);
}
.body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-list .sidebar-content .sidebar-item .sidebar-link .sidebar-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
}
.body-wrapped .wrapped-left #sidebar .copy-right {
  margin-left: 8px;
  text-align: center;
}
.body-wrapped .wrapped-left #sidebar .copy-right .text {
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 3px;
  word-spacing: 2px;
  text-align: center;
  line-height: 25px;
  color: #ffffff;
}
.body-wrapped .wrapped-right {
  min-height: 100vh;
  width: 100%;
}
.body-wrapped .wrapped-right:not(.dashboad) .main {
  min-height: calc(100vh - 110px);
}
.body-wrapped .wrapped-right:not(.dashboad) .main .section.section-error {
  min-height: calc(100vh - 90px - 110px);
}
.body-wrapped .wrapped-right:not(.dashboad) .main .section.section-border-top {
  padding: 50px 36px;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .section .section-header .header-desc {
  font-size: 13.3px;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table {
  border: 1px solid #b5bdd8;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table td,
.body-wrapped .wrapped-right:not(.dashboad) .main .table th {
  min-width: 140px;
  vertical-align: middle;
  white-space: nowrap;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table thead tr th {
  background: #7c89a8;
  color: #ffffff;
  font-size: 14px;
  font-family: montserrat-semibold;
  margin: 0;
  padding: 19px;
  text-transform: unset;
  border: none;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr {
  border-top: 4px solid #ffffff;
  border-bottom: 4px solid #ffffff;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr:hover {
  background-color: #daf4ef;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr.no-data {
  border: none;
  cursor: default !important;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr.no-data:hover {
  background-color: transparent;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr.no-data td:first-child:before {
  content: unset;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr.no-data td:after {
  content: unset;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr.no-data td .none {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 300px;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td {
  font-size: 13.3px;
  padding: 14px 18px;
  position: relative;
  border: none;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td:first-child:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  width: 4px;
  height: 100%;
  background: #7c89a8;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
  border: solid #b5bdd8;
  border-width: 1px 0 1px 0;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td .action {
  padding: 2px 8px;
  color: #ffffff;
  display: block;
  line-height: normal;
  text-align: center;
  position: relative;
  z-index: 1;
  border: none;
  width: 100%;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.pending .status {
  color: #ec8a0b;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.pending .action {
  background-color: #0bd08c;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.active .status {
  color: #0bd08c;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.active .action {
  background-color: #ec8a0b;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.expire .status {
  color: #da0000;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.expire .action {
  background-color: #ec8a0b;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.waitFinish .status {
  color: #7c89a8;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.waitFinish .action {
  background-color: #7c89a8;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.end .status {
  color: #7c89a8;
}
.body-wrapped .wrapped-right:not(.dashboad) .main .table tbody tr td.end .action {
  background-color: #da0000;
}
.body-wrapped .wrapped-right .header {
  background-image: url("/assets/images/bg_header.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.body-wrapped .wrapped-right .header .header-wrapped {
  padding: 30px 0 39px;
  position: relative;
  z-index: 1;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-mobile {
  display: none;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-mobile .header-right .show-sidebar {
  cursor: pointer;
  color: #ffffff;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-mobile .header-right .show-sidebar i {
  font-size: 40px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-mobile .header-right .show-sidebar.active {
  opacity: 0;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav {
  margin: 0 26px 0 20px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left {
  margin-right: 9px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item {
  display: flex;
  align-items: center;
  justify-content: normal;
  flex-wrap: nowrap;
  position: relative;
  padding-left: 53px;
  padding-right: 10px;
  min-width: 167px;
  margin-right: 10px;
  background: #303a51;
  border-radius: 50px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item:last-child {
  margin-right: 0;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: -1;
  border: 3px solid #22d167;
  border-radius: 50px;
  filter: blur(5px);
  transition: all 0.5s ease;
  opacity: 0;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180px;
  height: 51px;
  background: linear-gradient(to bottom, #10bdd9 0%, #0be995 100%);
  border-radius: 50px;
  z-index: -1;
  opacity: 0;
  transition: all 0.5s ease;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item:hover:before {
  opacity: 1;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item:hover:after {
  opacity: 0.4;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item .wallet-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item .wallet-icon .icon-name {
  position: absolute;
  top: 11px;
  left: 6px;
  transform: translate(0, 0);
  font-size: 12px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item .wallet-content p {
  margin-bottom: 0px;
  font-size: 13.5px;
  font-family: daxline;
  color: #ffffff;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item .wallet-content .wallet-title {
  margin-top: 3px;
  margin-bottom: -2px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .border-linear {
  background: linear-gradient(to bottom, #10bdd9 0%, #0be995 100%);
  padding: 1px;
  border-radius: 50px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-agency .agency-box {
  position: relative;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-agency .agency-box .link-agency {
  border: none;
  border-radius: 50px;
  height: 34px;
  width: 236px;
  padding: 5px 65px 5px 15px;
  text-overflow: ellipsis;
  color: #000000;
  font-size: 13.5px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-agency .agency-box .link-agency::placeholder {
  font-family: montserrat-italic;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-agency .agency-box .btn-copy {
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translate(0, -50%);
  color: #ffffff;
  height: calc(100% - 7px);
  min-width: 55px;
  border-radius: 50px;
  border: none;
  background: linear-gradient(to bottom, #10bdd9 0%, #0be995 100%);
  padding: 0px 6px;
  font-size: 13px;
  font-family: montserrat;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .btn-showNotifi,
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .btn-signOut {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #fff;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .btn-showNotifi i,
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .btn-signOut i {
  background: linear-gradient(to bottom, #10bdd9 0%, #0be995 100%);
  font-size: 20px;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-notification {
  margin-left: 6px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-welcome {
  margin-left: 5px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-welcome .account {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  position: relative;
  border-radius: 50px;
  background: #ffffff;
  min-width: 158px;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-welcome .account .account-name {
  padding-right: 18px;
  padding-left: 16px;
  font-size: 12.5px;
  font-family: montserrat-medium;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-welcome .account .account-avatar {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  background: #ffffff;
  border-left: 1px solid #000;
  overflow: hidden;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-welcome .account .account-avatar .avatar-img {
  width: 100%;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-welcome .account .account-status {
  position: absolute;
  top: 3px;
  right: -3px;
  transform: translate(0, 0);
  background: linear-gradient(to bottom, #10bdd9 0%, #0be995 100%);
  border-radius: 100%;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-welcome .account .account-status i {
  font-size: 13px;
  color: #ffffff;
}
.body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-signOut {
  margin-left: 10px;
}
.body-wrapped .wrapped-right .main {
  padding: 45px 50px;
  background-color: #e6f2ff;
  min-height: calc(100vh - 165px);
}
.body-wrapped .wrapped-right .main .section .section-header {
  padding-left: 100px;
  position: relative;
  min-height: 57px;
}
.body-wrapped .wrapped-right .main .section .section-header .header-icon {
  position: absolute;
  top: 0;
  left: 3px;
  transform: translate(0, 0);
  min-width: 64px;
  text-align: center;
  padding-bottom: 16px;
}
.body-wrapped .wrapped-right .main .section .section-header .header-icon:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: 5px;
  width: 100%;
  background: #00d58b;
}
.body-wrapped .wrapped-right .main .section .section-header .header-title {
  color: #403d5d;
  font-size: 25px;
  font-family: montserrat-semibold;
  margin: 0 0 7px;
  padding: 0;
  text-transform: uppercase;
}
.body-wrapped .wrapped-right .main .section .section-header .header-desc {
  color: #403d5d;
  font-size: 13.5px;
  font-family: montserrat-italic;
  margin: 0 0 0 2px;
  padding: 0;
  text-transform: none;
}
.body-wrapped .wrapped-right .main .section .section-content .table-func {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 34px;
}
.body-wrapped .wrapped-right .main .section .section-content .table-func .config-pageSize select {
  padding: 7px 15px 7px 10px;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content {
  margin: 30px auto 0;
  max-width: 984px;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .table-cooperate th,
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .table-cooperate td {
  text-align: center;
  font-weight: normal;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .table-cooperate thead tr th {
  background: #474b67;
  white-space: nowrap;
  padding: 20px 26px;
  color: #ffffff;
  font-size: 14px;
  border: none;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .table-cooperate thead tr th:first-child {
  width: 50px;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .table-cooperate thead tr th:nth-child(2) {
  width: 369px;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .table-cooperate tbody tr td {
  border: solid #ffffff;
  border-width: 3px 5px;
  color: #404463;
  background: #e6f2ff;
  vertical-align: middle;
  font-size: 14px;
  padding: 8px 9px;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .table-cooperate tbody tr td:first-child {
  border-left: 0;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .table-cooperate tbody tr td:last-child {
  border-right: 0;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .cooperate-note {
  margin: 37px 0 15px;
  font-size: 14.5px;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .cooperate-note .note-text {
  margin-bottom: 0;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .cooperate-note .note-text b {
  margin-right: 28px;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .cooperate-note .note-list {
  padding-left: 0;
  list-style: none;
  margin: 3px 5px;
}
.body-wrapped .wrapped-right .main .section .section-content.cooperate-content .cooperate-note .note-list .note-item {
  margin-bottom: 4px;
}
.body-wrapped .wrapped-right .main .section .section-content.agency-content {
  margin-top: 30px;
  background-image: url("/assets/images/img_world_map.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 70px;
}
.body-wrapped .wrapped-right .main .section .section-content.agency-content .agency-list {
  max-width: 812px;
  margin: 0 auto;
}
.body-wrapped .wrapped-right .main .section .section-content.agency-content .agency-list .agency-item {
  border-left: 3px solid #00d58b;
  padding-left: 18px;
  margin-bottom: 25px;
}
.body-wrapped .wrapped-right .main .section .section-content.agency-content .agency-list .agency-item .item-header .item-title {
  font-size: 17.1px;
  margin-bottom: 4px;
  font-family: montserrat-semibold;
}
.body-wrapped .wrapped-right .main .section .section-content.agency-content .agency-list .agency-item .item-content p {
  text-indent: 35px;
}
.body-wrapped .wrapped-right .main .section .section-content.agency-content .agency-list .agency-item .item-content .subtitle {
  font-size: 15.4px;
  margin-bottom: 2px;
  font-family: montserrat-bold;
}
.body-wrapped .wrapped-right .main .section .section-content.agency-content .agency-list .agency-item .item-content .text {
  font-size: 14.6px;
  margin-bottom: 4px;
  line-height: 23px;
}
.body-wrapped .wrapped-right .main .section.section-border-top {
  background-color: #fff;
  padding: 45px 25px;
  margin: 0 0 15px;
  border-radius: 0;
  position: relative;
  box-shadow: 0px 0px 35px 0px rgba(49, 61, 87, 0.15);
}
.body-wrapped .wrapped-right .main .section.section-border-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  width: 100%;
  height: 5px;
  background: #00d58b;
}
.body-wrapped .wrapped-right .main .section.section-border-top:last-child {
  margin-bottom: 0;
}
.body-wrapped .wrapped-right .main .section.section-statistic {
  position: relative;
  z-index: 1;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-col {
  padding: 0 15px;
  margin-bottom: 15px;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-col:last-child {
  margin-bottom: 0;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-box:hover .statistic-item {
  top: -11px;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item {
  position: relative;
  top: 0;
  width: 100%;
  padding: 12px;
  padding-left: 72px;
  background: #ffffff;
  min-height: 155px;
  border-radius: 5px;
  z-index: 0;
  overflow: hidden;
  transition: all 0.35s ease;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item.total-invest:before {
  background-image: url("/assets/images/bg_statistical_pink.png");
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item.total-profit:before {
  background-image: url("/assets/images/bg_statistical_green.png");
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item.total-agency:before {
  background-image: url("/assets/images/bg_statistical_blue.png");
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item.total-turnover:before {
  background-image: url("/assets/images/bg_statistical_orange.png");
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item .statistic-icon {
  position: absolute;
  top: 12px;
  left: 10px;
  transform: translate(0, 0);
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item .statistic-content {
  position: relative;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item .statistic-content .statistic-title {
  font-family: daxline;
  font-size: 16.6px;
  text-transform: uppercase;
  margin-bottom: 88px;
  text-align: right;
}
.body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-item .statistic-content .statistic-number {
  font-family: digital-italic;
  font-size: 25px;
  line-height: normal;
  display: table;
  margin-left: auto;
  margin-right: 18px;
  letter-spacing: 1px;
  color: #ffffff;
  margin-bottom: 0;
}
.body-wrapped .wrapped-right .main .section.section-position {
  margin: 20px 0;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped {
  position: relative;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list {
  position: relative;
  z-index: 1;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item {
  position: relative;
  display: flex;
  padding: 24px 19px;
  padding-right: 5px;
  max-width: 313px;
  background: #ffffff;
  border-radius: 0 10px 10px 0;
  margin-left: 9px;
  margin-bottom: 16px;
  z-index: 0;
  overflow: hidden;
  box-shadow: 0px 0px 15px 0px rgba(6, 7, 50, 0.1);
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item:last-child {
  margin-bottom: 0;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item.active {
  margin-left: 0;
  margin-bottom: 23px;
  box-shadow: 10px 10px 20px 0px rgba(39, 198, 103, 0.39);
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item.active:after {
  width: 100%;
  background: linear-gradient(to right, #25a991 0%, #27c568 100%);
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item.active .position-status .tick {
  background: #ffffff;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item.active .position-status .tick i {
  display: block;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item.active .position-name {
  color: #ffffff;
  text-transform: uppercase;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  width: 3px;
  height: 100%;
  background: #00d58b;
  z-index: -1;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item .position-status {
  margin-right: 15px;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item .position-status .tick {
  display: block;
  background: #c1d3e7;
  border-radius: 100%;
  padding: 2px;
  position: relative;
  width: 19px;
  height: 19px;
  color: #00d58b;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item .position-status .tick i {
  display: none;
}
.body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-img {
  position: absolute;
  top: 50%;
  right: 32px;
  transform: translate(0, -50%);
}
.body-wrapped .wrapped-right .main .section.section-cooperate {
  padding-bottom: 35px;
}
.body-wrapped .wrapped-right .main .section.section-agency {
  padding-bottom: 65px;
}
.body-wrapped .wrapped-overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

@media (min-width: 1200px) {
  .body-wrapped .wrapped-right .main .section .section-content.cooperate-content {
    margin-top: 72px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.agency-content {
    margin-top: 58px;
  }
  .body-wrapped .wrapped-right .main .section.section-position {
    margin-top: 75px;
    margin-bottom: 88px;
  }
  .body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped {
    padding-left: 54px;
  }
}
@media (min-width: 992px) {
  .body-wrapped .wrapped-right {
    width: calc(100% - 275px);
    margin-left: auto;
  }
  .body-wrapped .wrapped-right.dashboad .header .header-wrapped {
    padding: 30px 0 94px;
  }
  .body-wrapped .wrapped-right.dashboad .main {
    padding: 15px 50px 45px;
  }
  .body-wrapped .wrapped-right .main .section.section-border-top {
    margin-bottom: 50px;
  }
  .body-wrapped .wrapped-right .main .section.section-statistic {
    margin-top: -65px;
  }
}
@media (min-width: 768px) {
  .body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-col {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (min-width: 1510px) {
  .body-wrapped .wrapped-right:not(.dashboad) .main .table td,
  .body-wrapped .wrapped-right:not(.dashboad) .main .table th {
    white-space: normal;
  }
  .body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-col {
    flex-basis: 25%;
    max-width: 25%;
  }
}
@media (max-width: 1379px) {
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped {
    justify-content: center;
  }
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item {
    margin-bottom: 15px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.agency-content {
    background-size: cover;
    background-position: center;
  }
  .body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-img img {
    width: 100%;
  }
}
@media (max-width: 1199px) {
  .body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list {
    max-width: 550px;
    margin: 0 auto;
  }
  .body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item {
    max-width: unset;
  }
}
@media (max-width: 991px) {
  .body-wrapped .wrapped-left {
    left: calc(-275px - 100px);
  }
  .body-wrapped .wrapped-left.active {
    left: 0;
  }
  .body-wrapped .wrapped-left #sidebar .sidebar-wrapped .sidebar-header .close-sidebar {
    display: table;
  }
  .body-wrapped .wrapped-right .header {
    height: auto;
  }
  .body-wrapped .wrapped-right .header .header-wrapped {
    padding-bottom: 20px;
  }
  .body-wrapped .wrapped-right .header .header-wrapped .header-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 15px;
  }
  .body-wrapped .wrapped-right .main {
    padding: 15px;
  }
  .body-wrapped .wrapped-overlay.active {
    display: block;
    opacity: 1;
  }
}
@media (max-width: 767px) {
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav {
    margin: 0;
  }
  .body-wrapped .wrapped-right .main .section .section-header .header-title {
    font-size: 20px;
  }
  .body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped {
    justify-content: center;
  }
  .body-wrapped .wrapped-right .main .section.section-statistic .section-statistic-wrapped .statistic-col {
    flex-basis: 100%;
    max-width: 365px;
  }
  .body-wrapped .wrapped-right .main .section.section-border-top {
    padding: 20px 15px !important;
  }
}
@media (max-width: 575px) {
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-agency {
    flex-basis: 100%;
    margin-bottom: 15px;
  }
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-agency .agency-box .link-agency {
    width: 100%;
  }
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-notification {
    margin-left: 0;
  }
}
@media (max-width: 479px) {
  .form-half .form-item {
    flex-basis: 100%;
  }
  .pagi .pagi-item {
    margin: 2px;
  }
  .pagi .pagi-item.pagi-prev {
    margin-right: 0;
  }
  .pagi .pagi-item.pagi-next {
    margin-left: 0;
  }
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item {
    margin-left: 5px;
    margin-right: 5px;
  }
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-left .wallet .wallet-item:last-child {
    margin-right: 5px;
  }
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-notification,
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-welcome,
  .body-wrapped .wrapped-right .header .header-wrapped .header-desktop .header-nav .nav-wrapped .nav-right .header-signOut {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .body-wrapped .wrapped-right .main .section .section-header {
    padding-left: 0;
    min-height: auto;
  }
  .body-wrapped .wrapped-right .main .section.section-position .section-position-wrapped .position-list .position-item .position-name {
    font-size: 14px;
  }
}
.body-wrapped .wrapped-right .main .section .section-content.diagram-content {
  max-width: 1200px;
  margin: 54px auto 0;
}
.body-wrapped .wrapped-right .main .section .section-content.diagram-content .diagram-position {
  display: flex;
  align-items: center;
  justify-content: normal;
  flex-wrap: wrap;
  margin-bottom: 34px;
}
.body-wrapped .wrapped-right .main .section .section-content.diagram-content .diagram-position .position-text {
  margin-top: 2px;
  font-size: 14.5px;
}
.body-wrapped .wrapped-right .main .section .section-content.diagram-content .diagram-position .position-name {
  background: #00d58b;
  color: #ffffff;
  border: none;
  display: table;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 14.6px;
  cursor: default;
  margin-left: 25px;
  padding: 10px;
  text-align: center;
}
.body-wrapped .wrapped-right .main .section .section-content.diagram-content .diagram-list .diagram-control {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
}
.body-wrapped .wrapped-right .main .section .section-content.diagram-content .diagram-list .diagram-control .control-item {
  background: #e6f2ff;
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 0 25%);
  padding: 4px 24px;
  border-radius: 2px;
  font-size: 13.5px;
  margin-right: 3px;
  color: #49496d;
  font-weight: bold;
  border: none;
}
.body-wrapped .wrapped-right .main .section .section-content.diagram-content .diagram-list .diagram-control .control-item.active {
  background: #00d58b;
  color: #ffffff;
  pointer-events: none;
  cursor: default;
}
.body-wrapped .wrapped-right .main .section .section-content.diagram-content .diagram-list .diagram-table {
  min-height: 385px;
  background: #e6f2ff;
}

@media (max-width: 479px) {
  .body-wrapped .wrapped-right .main .section .section-content.diagram-content .diagram-position .position-name {
    margin: 10px auto 0;
  }
}
.form-readonly,
.bank-form {
  margin-top: 15px;
}
.form-readonly .form .form-item,
.bank-form .form .form-item {
  width: 100%;
  margin-bottom: 15px;
}
.form-readonly .form .form-item:last-child,
.bank-form .form .form-item:last-child {
  margin-bottom: 0;
}
.form-readonly .form .form-item .item-label,
.bank-form .form .form-item .item-label {
  margin-bottom: 1px;
  font-size: 13.3px;
}
.form-readonly .form .form-item input,
.form-readonly .form .form-item .item-val,
.bank-form .form .form-item input,
.bank-form .form .form-item .item-val {
  width: 100%;
  padding: 8px 15px;
  font-size: 13.3px;
  border-radius: 3px;
}

.form-readonly .form .form-item input,
.form-readonly .form .form-item .item-val {
  padding: 8px 15px 8px 33px;
  border: none;
  background: #dae0f4;
}

.person-information-form.form-readonly .form {
  display: flex;
  align-items: normal;
  justify-content: space-between;
  flex-wrap: wrap;
}

.account-info .text {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.account-info .text .btn-copy {
  color: #ffffff;
  background: #0bd08c;
  font-size: 12px;
  margin-left: 10px;
  padding: 0 16px;
  border-radius: 5px;
  line-height: normal;
  font-family: montserrat-semibold;
}
.account-info .text span {
  margin-left: 2px;
}

.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box {
  margin: 30px 0;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .contract-information {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .header-title {
  font-size: 14.5px;
  font-family: montserrat-semibold;
  margin-bottom: 0;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .person-information {
  display: table;
  position: relative;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .next-step,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .next-step,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .person-information .next-step {
  text-align: center;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term-header,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail-header,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .person-information-header {
  text-align: center;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term-desc,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail-desc,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .person-information-desc {
  text-align: justify;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-header {
  margin-bottom: 22px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-box.no-period .period-item .invest-price {
  max-width: 322px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-control .control-item {
  margin-right: 70px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-note {
  margin-bottom: 22px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-note .note-title {
  font-size: 15px;
  margin-bottom: 12px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-note .note-desc {
  font-size: 13.3px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-selector {
  margin-bottom: 15px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-selector .selector-item {
  margin-right: 13px;
  width: 100%;
  min-width: 210px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-selector .selector-item:last-child {
  margin-right: 0;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-profit {
  margin-bottom: 10px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-profit .text {
  font-size: 13.6px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-profit .text b {
  font-size: 15px;
  padding-left: 2px;
  margin-right: 2px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item .invest-price .price-label {
  font-size: 15px;
  margin-bottom: 14px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .next-step .btn-next {
  margin-left: 4px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-header {
  margin-bottom: 17px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-desc .text {
  font-size: 13.3px;
  word-spacing: 0.7px;
  line-height: 21px;
  margin-bottom: 8px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-desc .term-list {
  margin-left: 20px;
  margin-bottom: 10px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-desc .term-list .text {
  margin-bottom: 0px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-desc .term-table {
  border: 1px solid #b5bdd8;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-desc .term-table th,
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-desc .term-table td {
  border: 1px solid #b5bdd8;
  padding: 5px;
  text-align: center;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-desc .term-table td:nth-child(2) {
  text-align: left;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-confirm {
  display: table;
  margin: 20px auto 0;
  padding-left: 30px;
  cursor: pointer;
  position: relative;
  line-height: normal;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-confirm.active .confirm-tick i {
  transform: scale(1);
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-confirm .confirm-tick {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 14px;
  height: 14px;
  background: #909cc4;
  border-radius: 3px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-confirm .confirm-tick i {
  transition: all 0.35s ease;
  font-size: 14px;
  transform: scale(0);
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term .term-content .term-confirm .text {
  font-size: 13.3px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-header {
  margin-bottom: 20px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-header .header-title {
  margin-bottom: 27px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-header .header-desc {
  font-size: 14.5px;
  margin-left: 6px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-box {
  margin-bottom: 25px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-control {
  margin-left: 18px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-account {
  margin-left: 5px;
  margin-top: 23px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-account .account-selector {
  margin-bottom: 25px;
  max-width: 207px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-account .account-selector .account-label {
  font-size: 14.7px;
  margin-bottom: 7px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-account .account-info .text {
  font-size: 14.6px;
  margin-bottom: 11px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-account .account-info .text:last-child {
  margin-bottom: 0;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .next-step {
  margin-left: 4px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-header {
  margin-bottom: 25px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .text {
  font-size: 13.3px;
  line-height: 21px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .text .text-color {
  margin-left: 4px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .detail-list {
  margin-top: 9px;
  margin-bottom: 17px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .detail-list .indent-text {
  margin-left: 37px;
  font-family: montserrat-medium-italic;
  font-size: 13.3px;
  line-height: 21px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .detail-func {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 5px 0;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .detail-func .func-item {
  margin: 5px 6.5px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .detail-func .func-item .btn {
  font-size: 13.3px;
  padding: 5px 10px;
  border-radius: 3px;
  color: #ffffff;
  min-width: 119px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .person-information .person-information-header {
  padding-right: 38px;
  margin-bottom: 25px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .person-information .person-information-content .person-information-desc .text {
  font-size: 13.3px;
  line-height: 21px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .next-step {
  margin-top: 18px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .history-invest .history-header .header-title {
  color: #2f3c5b;
  font-size: 16.5px;
  font-family: montserrat-semibold;
  margin: 0 0 0 25px;
  padding: 0;
  text-transform: uppercase;
  text-decoration: underline;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .history-invest .history-content .history-box {
  margin-top: 37px;
}
.body-wrapped .wrapped-right .main .section .section-content.information-content .history-invest .history-content .history-box .table tbody tr {
  cursor: pointer;
}

.modal .modal-border-top .modal-content:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  width: 100%;
  height: 4px;
  background: #0bd08c;
}
.modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-header .header-title {
  text-align: center;
  font-size: 22px;
  font-family: montserrat-semibold;
  margin: 20px 0;
}
.modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item {
  margin-bottom: 40px;
}
.modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item:last-child {
  margin-bottom: 0;
}
.modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item.view-pdf {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item.view-pdf .view-item {
  margin: 0 5px;
}
.modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item .item-header .header-title {
  font-size: 16px;
  font-family: montserrat-semibold;
  margin-bottom: 20px;
}
.modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item .item-content .item-info {
  width: 100%;
  max-width: 545px;
  margin: 0 auto;
}
.modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item .item-content .item-info .account-info .text {
  margin-bottom: 11px;
}
.modal .modal-contract-detail .modal-content .modal-footer {
  margin-bottom: 20px;
}
.modal .modal-notification {
  text-align: center;
}
.modal .modal-notification .modal-content .modal-body .notification .notification-header .header-title {
  font-size: 16px;
  font-family: montserrat-semibold;
  margin-top: 20px;
}
.modal .modal-notification .modal-content .modal-body .notification .notification-header .text {
  font-size: 15px;
  margin-bottom: 10px;
}
.modal .modal-notification .modal-content .modal-body .notification .notification-content .notification-icon {
  margin-bottom: 20px;
}
.modal .modal-preview-contract {
  max-width: 960px;
}
.modal .modal-preview-contract .modal-content .modal-header {
  border: none;
  padding-top: 35px;
}
.modal .modal-preview-contract .modal-content .modal-header .modal-title {
  flex-basis: 100%;
  text-align: center;
  font-family: montserrat-semibold;
  text-transform: uppercase;
}
.modal .modal-preview-contract .modal-content .modal-header .close {
  position: absolute;
  top: 15px;
  right: 15px;
  transform: translate(0, 0);
}
.modal .modal-preview-contract .modal-content .modal-body .preview {
  margin: 15px 30px;
  overflow-wrap: break-word;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-header {
  text-align: center;
  font-family: montserrat-semibold;
  margin-bottom: 20px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-header .header-title {
  font-size: 24px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content i {
  display: inline;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content ol {
  margin: 0;
  padding: 0;
  counter-reset: item;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content ol > li {
  margin: 0;
  padding: 0 0 0 2em;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
  font-size: 16px;
  line-height: 24px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content ol > li:before {
  content: counter(item) ".";
  display: inline-block;
  min-width: 1em;
  margin-right: 15px;
  font-weight: bold;
  text-align: right;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-header {
  text-align: center;
  margin-bottom: 20px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-header .header-title {
  font-size: 22px;
  font-family: montserrat-semibold;
  margin-bottom: 0;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .desc-text {
  position: relative;
  font-size: 16px;
  line-height: 24px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .desc-text.note {
  margin: 10px 0;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .desc-text.text-indent {
  text-indent: 30px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .desc-text.text-indent ul {
  padding-left: 30px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .desc-text .text-line {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 0);
  text-indent: 0;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-law,
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-date {
  margin-bottom: 10px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-info {
  margin-top: 5px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-info .contract-item {
  margin-bottom: 5px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-info .contract-item .item-header {
  margin-bottom: 5px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-info .contract-item .item-header .item-title {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 20px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-info .contract-item .item-content .item-desc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-size: 16px;
  margin-bottom: 5px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-term .term-header {
  margin-bottom: 10px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-term .term-header .term-set .set-header {
  text-align: center;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-term .term-header .term-set .set-header .set-title {
  font-size: 22px;
  font-weight: bold;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-term .term-header .term-set .set-content .set-desc {
  font-style: italic;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-term .term-content .term-item {
  margin-bottom: 15px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-term .term-content .term-item .item-header .item-title {
  font-weight: bold;
  font-size: 20px;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-sign .sign-box {
  display: table;
  margin: 0 auto;
  border: 1px solid #cbd0d7;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-sign .sign-box .sign-item {
  text-align: center;
  min-height: 200px;
  display: inline-block;
  width: 250px;
  position: relative;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-sign .sign-box .sign-item .vertical-line {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 1px;
  height: 100%;
  background: rgb(203, 208, 215);
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-sign .sign-box .sign-item .item-desc {
  margin: 5px 0;
}
.modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-sign .sign-box .sign-item .item-desc .desc-text {
  font-weight: bold;
  text-transform: uppercase;
}
.modal .modal-preview-contract .modal-content .modal-footer .btn {
  font-size: 13.3px;
  padding: 5px 10px;
  border-radius: 3px;
  color: #ffffff;
  min-width: 119px;
}
.modal .modal-confirm-finish .confirm-finish {
  text-align: center;
  font-family: montserrat-semibold;
}
.modal .modal-confirm-finish .confirm-finish .confirm-title {
  font-size: 2em;
  margin: 20px 0;
}
.modal .modal-confirm-finish .confirm-finish .confirm-text {
  font-size: 1.25em;
}
.modal .modal-confirm-finish .modal-footer {
  padding-bottom: 30px;
}
.modal .modal-footer {
  border: none;
  justify-content: center;
}

@media (min-width: 1300px) {
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box {
    margin: 50px 0 0 60px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .detail-account .account-info {
    margin-left: 20px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.information-content .history-invest {
    margin-top: 66px;
  }
}
@media (min-width: 992px) {
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .person-information .person-information-content .person-information-form {
    margin-top: 30px;
  }
  .modal .modal-contract-detail .modal-content .modal-body {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (min-width: 576px) {
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content {
    margin-left: 13px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-selector {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
}
@media (min-width: 480px) {
  .person-information-form .form {
    margin: 0 -20px;
  }
  .person-information-form .form .form-item {
    width: 50%;
    padding: 0 20px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-control .control-item {
    margin-right: 43px;
  }
  .modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-info .contract-item .item-content .item-desc .desc-title {
    flex-basis: 100%;
    max-width: 175px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
@media (max-width: 1199px) {
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period,
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment,
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .term,
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail,
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .person-information {
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .modal .modal-preview-contract {
    margin: 0.5rem;
  }
  .modal .modal-preview-contract .modal-content .modal-body .preview {
    margin: 0 !important;
  }
}
@media (max-width: 575px) {
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-selector .selector-item {
    min-width: unset;
    margin-bottom: 10px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-selector .selector-item:last-child {
    margin-bottom: 0;
  }
  .modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-sign .sign-box .sign-item {
    width: 100% !important;
  }
  .modal .modal-preview-contract .modal-content .modal-body .preview .preview-content .preview-contract .contract-detail .contract-content .contract-sign .sign-box .sign-item .vertical-line {
    top: unset !important;
    bottom: 0;
    width: 100% !important;
    height: 1px !important;
  }
}
@media (max-width: 479px) {
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .period .period-content .period-item.period-control .control-item {
    margin-right: 20px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .payment .payment-content .payment-control .control-item:last-child {
    margin-bottom: 0;
  }
  .body-wrapped .wrapped-right .main .section .section-content.information-content .information-box .detail .detail-content .detail-desc .detail-account .account-info {
    margin-left: 10px;
  }
  .modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item {
    margin-bottom: 15px;
  }
  .modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item.view-pdf .view-item {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .modal .modal-contract-detail .modal-content .modal-body .contract-detail .detail-content .detail-item .item-content .item-info .account-info .text {
    margin-bottom: 5px;
  }
}
.body-wrapped .wrapped-right .main .section .section-content.history-content {
  margin-top: 20px;
}
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item {
  margin: 0 8px 10px;
}
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item .filter-label {
  color: #7c89a8;
  font-size: 14.5px;
}
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item .filter-label.label-hide {
  visibility: hidden;
}
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item .filter-selector,
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item .filter-val {
  min-width: 207px;
}
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item .filter-date .react-datepicker-wrapper .react-datepicker__input-container input {
  max-width: 207px;
}
.body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item .filter-date .datepicker-icon {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
  font-size: 22px;
  color: #7c89a8;
  z-index: -1;
}

@media (min-width: 1300px) {
  .body-wrapped .wrapped-right .main .section .section-content.history-content {
    margin-top: 50px;
  }
}
@media (max-width: 575px) {
  .body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-box {
    flex-basis: 100%;
  }
  .body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item {
    flex-basis: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item .filter-date .react-datepicker-wrapper .react-datepicker__input-container input {
    max-width: unset;
  }
  .body-wrapped .wrapped-right .main .section .section-content.history-content .history-filter .filter-item.filter-func {
    flex-basis: 100%;
    text-align: center;
  }
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-box {
  margin: 20px auto 43px;
  display: table;
  position: relative;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-info {
  margin-bottom: 34px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-info .total-label {
  font-size: 15.2px;
  margin-bottom: 2px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-info .total-price {
  display: table;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-control {
  margin-bottom: 25px;
  margin-left: 33px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-control .control-item {
  margin-right: 71px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-form {
  margin: 0 -5px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-form .form-item {
  padding: 0 5px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-form .form-item .item-label {
  font-size: 14.5px;
  font-family: montserrat-semibold;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-form .form-half {
  margin-bottom: 18px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-func {
  margin: 27px 0 0;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-notification {
  margin: 50px 0;
  text-align: center;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-notification .notification-header {
  margin-bottom: 10px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-notification .notification-header .header-title {
  font-size: 14.5px;
  font-family: montserrat-semibold;
  margin-bottom: 0;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-notification .notification-content .notification-desc .text {
  font-size: 13.3px;
}
.body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-notification .notification-content .notification-icon {
  margin: 20px 0;
}

@media (min-width: 992px) {
  .body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-box {
    margin-top: 58px;
    margin-left: 25px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-form .form {
    width: 537px;
  }
}
@media (max-width: 479px) {
  .body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-form .form-half .form-item {
    margin-bottom: 18px;
  }
  .body-wrapped .wrapped-right .main .section .section-content.withdraw-content .withdraw-form .form-half .form-item:last-child {
    margin-bottom: 0;
  }
}
.body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-form {
  margin-top: 4.2em;
  margin-bottom: 2em;
}
.body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-form .form .form-item .item-label {
  font-size: 14.5px;
  margin-bottom: 3px;
}
.body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-form .form .form-item .insert-func .btn-save {
  margin-right: 10px;
}
.body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-note .note-title {
  text-decoration: underline;
  color: #ff0055;
  font-family: montserrat-semibold;
  font-size: 14.5px;
}
.body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-note .note-text {
  font-size: 14.5px;
}
.body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-img {
  max-width: 480px;
}

@media (min-width: 1500px) {
  .body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-form {
    margin: 5.2em 6.5em 2.7em 7.3em;
  }
  .body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-note {
    margin-left: 1.7em;
  }
  .body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-note .note-text {
    width: 690px;
  }
}
@media (max-width: 1199px) {
  .body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-form {
    margin-top: 2em;
  }
}
@media (max-width: 479px) {
  .body-wrapped .wrapped-right .main .section .section-content.bank-content .bank-form .form .form-item .insert-func .btn {
    padding: 5px 30px;
  }
}