// Background Page

$bgHeader: url('/assets/images/bg_header.png');
$bgSidebar: url('/assets/images/bg_sidebar.png');
$bgStatisticPink: url('/assets/images/bg_statistical_pink.png');
$bgStatisticGreen: url('/assets/images/bg_statistical_green.png');
$bgStatisticBlue: url('/assets/images/bg_statistical_blue.png');
$bgStatisticOrange: url('/assets/images/bg_statistical_orange.png');
$bgMapWorld: url('/assets/images/img_world_map.png');

// End

// Background Authentication
$bgAuth: url('/assets/images/auth/bg-auth.jpg');

// End

// Fonts

$digitalItalic: url('/assets/fonts/digital-7-italic.ttf');

$daxLine: url('/assets/fonts/UTM-Dax.ttf');
$daxLineBold: url('/assets/fonts/UTM-DaxBold.ttf');

$montserrat: url('/assets/fonts/Montserrat-Regular.ttf');
$montserratSemibold: url('/assets/fonts/Montserrat-SemiBold.ttf');
$montserratItalic: url('/assets/fonts/Montserrat-Italic.ttf');
$montserratMedium: url('/assets/fonts/Montserrat-Medium.ttf');
$montserratBold: url('/assets/fonts/Montserrat-Bold.ttf');
$montserratMediumItalic: url('/assets/fonts/Montserrat-MediumItalic.ttf');
$montserratLight: url('/assets/fonts/Montserrat-Light.ttf');

//End

// Color
$headerTitleColor:#403d5d;
$theadHistoryColor: #7c89a8;
$confirmTermColor: #909cc4;
$inputColor:#dae0f4;

$whiteColor: #ffffff;
$blackColor:#000000;

$lightPurpleColor:#67708f;
$purpleDarkColor :#474b67;

$redColor: #ff0055;
$redBtnColor: #e34039;
$redDarkColor:#da0000;
$redBrownColor: #ad063a;

$yellowColor: #fcff00;

$orangeColor:#ec8a0b;

$blueColor:#00a2ff;

$brownColor: #4c0300;

$grayDarkColor: #566382;
$grayColor: #cbd0d7;

$lightGreenColor:#0af46d;
$greenColor: #00d58b;
$greenDarkColor: #11bc61;
$greenBtnColor:#0bd08c;

$lightBlueColor:#a8c4e6;

$linearToBottom: linear-gradient(to bottom, #10bdd9 0%, #0be995 100%);
$linearSideBar: linear-gradient(to bottom, #18d160 0%, #1da898 100%);

// End Color

// Mixin

@mixin fontSize13-3 {
  font-size: 13.3px;
}

@mixin fontSize14-5 {
  font-size: 14.5px;
}

@mixin fontSize14 {
  font-size: 14px;
}

@mixin fontSize16 {
  font-size: 16px;
}

@mixin fontMontserratSemibold {
  font-family: montserrat-semibold;
}

@mixin unstyle {
  padding-left: 0;
  list-style: none;
}

@mixin modalHasBorderTop {
  &:before {
    content: "";
    @include positionTopLeft(absolute, 0, 0, translate(0, 0));
    width: 100%;
    height: 4px;
    background: $greenBtnColor;
  }
}

@mixin displayFlex($align, $justify, $wrap) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-wrap: $wrap;
}

@mixin title($color, $fontSize, $fontFamily, $margin, $padding, $textTranform) {
  color: $color;
  font-size: $fontSize;
  font-family: $fontFamily;
  margin: $margin;
  padding: $padding;
  text-transform: $textTranform;
}

@mixin bgImage($url, $repeat, $size, $position) {
  background-image: $url;
  background-size: $size;
  background-repeat: $repeat;
  background-position: $position;
}

@mixin positionTopLeft($position, $top, $left, $transform) {
  position: $position;
  top: $top;
  left: $left;
  transform: $transform;
}

@mixin positionTopRight($position, $top, $right, $transform) {
  position: $position;
  top: $top;
  right: $right;
  transform: $transform;
}


@mixin positionBottomLeft($position, $bottom, $left, $transform) {
  position: $position;
  bottom: $bottom;
  left: $left;
  transform: $transform;
}

@mixin positionBottomRight($position, $bottom, $right, $transform) {
  position: $position;
  bottom: $bottom;
  right: $right;
  transform: $transform;
}

@mixin section($bgColor, $padding, $margin, $radius) {
  background-color: $bgColor;
  padding: $padding;
  margin: $margin;
  border-radius: $radius;
}