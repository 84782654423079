.body-wrapped {
  .wrapped-right {
    overflow: hidden;
    .main {
      .section {
        &.section-position {
          max-width: 1066px;
          margin-left: auto;
          margin-right: auto;
          .section-position-wrapped {
            .position-img {
              #robot{
                z-index: -1;
              }
              &:after {
                content: "";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                bottom: -14%;
                left: 49%;
                transform: translateX(-50%);
                background-image: url(/assets/images/img_position.png);
                z-index: -2;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: center;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          &.section-position {
            .section-position-wrapped {
              .position-list {
                max-width: 100%;
                margin-right: 59%;
              }
              .position-img {
                right: 0;
                width: 63%;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          &.section-statistic {
            .section-statistic-wrapped {
              .statistic-col {
                flex-basis: 50%;
                max-width: 365px;
              }
              .statistic-item {
                .statistic-content {
                  position: unset;
                  .statistic-number {
                    position: absolute;
                    width: 100%;
                    height: 20px;
                    bottom: 5px;
                    left: 0;
                    margin: 0;
                    padding: 0 10px;
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .body-wrapped {
    .wrapped-right {
      .header {
        .header-wrapped {
          padding-top: 47px;
          .header-desktop {
            .header-nav {
              .nav-wrapped {
                .nav-right {
                  .header-welcome {
                    .account {
                      min-width: 35px;
                      .account-name {
                        display: none;
                      }
                    }
                  }
                  .header-agency {
                    flex-basis: unset;
                    margin: 0;
                    margin-right: 5px;
                    .agency-box {
                      max-width: 300px;
                    }
                  }
                }
                .nav-left {
                  position: absolute;
                  top: 0;
                  left: -15px;
                  width: calc(100% + 30px);
                  margin: 0;
                  .wallet {
                    flex-wrap: nowrap;
                    .wallet-item {
                      margin-bottom: 0;
                      margin: 0 !important;
                      width: 33.33%;
                      border-radius: 0;
                      padding-top: 3px;
                      padding-bottom: 3px;
                      min-width: unset;
                    }
                  }
                }
              }
            }
          }
          .header-mobile {
            padding-top: 19px;
          }
        }
      }
      .main {
        .section {
          &.section-position {
            .section-position-wrapped {
              .position-list {
                margin-right: 0;
                display: flex;
                flex-wrap: wrap;
                .position-item{
                  width: calc(50% - 5px);
                  margin-left: 5px;
                  max-height: 61px;
                  padding-left: 10px;
                  padding-top: 10px;
                  padding-bottom: 10px;
                  &:last-child{
                    margin-bottom: 16px;
                  }
                }
              }
              .position-img{
                position: relative;
                top: 0;
                transform: unset;
                width: 100%;
                z-index: 1;
                #robot{
                  z-index: -1;
                }
                &:after{
                  z-index: -2;
                }
              }
              
            }
          }
          &.section-statistic {.section-statistic-wrapped {.statistic-item {
            min-height: 121px;
            .statistic-content {.statistic-title{
            margin-bottom: 50px;
          }}}}}
        }
      }
    }
  }
}
@media (max-width: 479px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          &.section-statistic {
            .section-statistic-wrapped {
              .statistic-col {
                flex-basis: 100%;
                max-width: 365px;
              }
              .statistic-item {
                min-height: 95px;
                &:before {
                  top: 2px;
                  background-position: top;
                }
                .statistic-content {
                  position: unset;
                  .statistic-title {
                    margin-bottom: 53px;
                  }
                  .statistic-number {
                    bottom: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .body-wrapped {
    .wrapped-right {
      .header {
        .header-wrapped {
          .header-desktop {
            .header-nav {
              .nav-wrapped {
                .nav-right {
                  .header-agency {
                    width: 100%;
                    padding-right: 78px;
                    margin-bottom: 10px;
                  }
                }
                .nav-left {
                  .wallet {
                    .wallet-item {
                      padding-left: 15px;
                      border-right: 1px solid #fff;
                      .wallet-icon {
                        display: none;
                      }
                      &:last-child {
                        border: none;
                      }
                    }
                  }
                }
              }
            }
          }
          .header-mobile {
            align-items: center;
            justify-content: center;
            position: relative;
            .header-left {
              display: inline;
            }
            .header-right {
              position: absolute;
              top: 91px;
              right: 3px;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
