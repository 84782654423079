.body-wrapped {
  .wrapped-right {
    .main {
      .section {
        .section-content {
          &.diagram-content {
            max-width: 1200px;
            margin: 54px auto 0;

            .diagram-position {
              @include displayFlex(center, normal, wrap);
              margin-bottom: 34px;

              .position-text {
                margin-top: 2px;
                @include fontSize14-5;
              }

              .position-name {
                background: $greenColor;
                color: $whiteColor;
                border: none;
                display: table;
                border-radius: 3px;
                text-transform: uppercase;
                font-size: 14.6px;
                cursor: default;
                margin-left: 25px;
                padding: 10px;
                text-align: center;
              }
            }

            .diagram-list {
              .diagram-control {
                @include displayFlex(center, flex-start, nowrap);
                overflow-x: auto;

                .control-item {
                  background: #e6f2ff;
                  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%, 0 25%);
                  padding: 4px 24px;
                  border-radius: 2px;
                  font-size: 13.5px;
                  margin-right: 3px;
                  color: #49496d;
                  font-weight: bold;
                  border: none;

                  &.active {
                    background: $greenColor;
                    color: $whiteColor;
                    pointer-events: none;
                    cursor: default;
                  }
                }
              }

              .diagram-table {
                min-height: 385px;
                background: #e6f2ff;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:479px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.diagram-content {
              .diagram-position {
                .position-name {
                  margin: 10px auto 0;

                }
              }
            }
          }
        }
      }
    }
  }
}