.body-wrapped {
  .wrapped-right {
    .main {
      .section {
        .section-content {
          &.history-content {
            margin-top: 20px;

            .history-filter {
              @include displayFlex(flex-start, center, wrap);
              margin-bottom: 25px;

              .filter-box {
                @include displayFlex(center, center, wrap);
              }

              .filter-item {
                margin: 0 8px 10px;

                .filter-label {
                  color: $theadHistoryColor;
                  @include fontSize14-5;

                  &.label-hide {
                    visibility: hidden;
                  }
                }

                .filter-selector,
                .filter-val {
                  min-width: 207px;
                }

                .filter-date {
                  .react-datepicker-wrapper {
                    .react-datepicker__input-container {
                      input {
                        max-width: 207px;
                      }
                    }
                  }

                  .datepicker-icon {
                    @include positionTopRight(absolute, 50%, 5px, translate(0, -50%));
                    font-size: 22px;
                    color: $theadHistoryColor;
                    z-index: -1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width:1300px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.history-content {
              margin-top: 50px;
            }
          }
        }
      }
    }
  }
}

@media (max-width:575px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.history-content {
              .history-filter {

                .filter-box {
                  flex-basis: 100%;
                }

                .filter-item {
                  flex-basis: 100%;
                  margin-left: 0;
                  margin-right: 0;

                  .filter-date {
                    .react-datepicker-wrapper {
                      .react-datepicker__input-container {
                        input {
                          max-width: unset;
                        }
                      }
                    }
                  }

                  &.filter-func {
                    flex-basis: 100%;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}