@font-face {
  font-family: digital-italic;
  src: $digitalItalic;
}

@font-face {
  font-family: daxline;
  src: $daxLine;
}

@font-face {
  font-family: daxline-bold;
  src: $daxLineBold;
}

@font-face {
  font-family: montserrat;
  src: $montserrat;
}

@font-face {
  @include fontMontserratSemibold;
  src: $montserratSemibold;
}

@font-face {
  font-family: montserrat-italic;
  src: $montserratItalic;
}

@font-face {
  font-family: montserrat-medium;
  src: $montserratMedium;
}

@font-face {
  font-family: montserrat-bold;
  src: $montserratBold;
}

@font-face {
  font-family: montserrat-medium-italic;
  src: $montserratMediumItalic;
}

@font-face {
  font-family: montserrat-light;
  src: $montserratLight;
}