.body-wrapped {
  .wrapped-right {
    .main {
      .section {
        .section-content {
          &.bank-content {
            .bank-form {
              margin-top: 4.2em;
              margin-bottom: 2em;

              .form {
                .form-item {
                  .item-label {
                    @include fontSize14-5;
                    margin-bottom: 3px;
                  }

                  .insert-func {
                    .btn-save {
                      margin-right: 10px;
                    }
                  }
                }
              }
            }

            .bank-note {
              .note-title {
                text-decoration: underline;
                color: $redColor;
                @include fontMontserratSemibold;
                @include fontSize14-5;
              }

              .note-text {
                @include fontSize14-5;
              }
            }

            .bank-img {
              max-width: 480px;
            }
          }
        }
      }
    }
  }
}



@media (min-width:1500px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.bank-content {
              .bank-form {
                margin: 5.2em 6.5em 2.7em 7.3em;
              }

              .bank-note {
                margin-left: 1.7em;

                .note-text {
                  width: 690px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:1199px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.bank-content {
              .bank-form {
                margin-top: 2em;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:479px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.bank-content {
              .bank-form {
                .form {
                  .form-item {
                    .insert-func {
                      .btn {
                        padding: 5px 30px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}