@font-face {
  font-family: digital-italic;
  src: url("/assets/fonts/digital-7-italic.ttf");
}
@font-face {
  font-family: daxline;
  src: url("/assets/fonts/UTM-Dax.ttf");
}
@font-face {
  font-family: daxline-bold;
  src: url("/assets/fonts/UTM-DaxBold.ttf");
}
@font-face {
  font-family: montserrat;
  src: url("/assets/fonts/Montserrat-Regular.ttf");
}
@font-face {
  font-family: montserrat-semibold;
  src: url("/assets/fonts/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: montserrat-italic;
  src: url("/assets/fonts/Montserrat-Italic.ttf");
}
@font-face {
  font-family: montserrat-medium;
  src: url("/assets/fonts/Montserrat-Medium.ttf");
}
@font-face {
  font-family: montserrat-bold;
  src: url("/assets/fonts/Montserrat-Bold.ttf");
}
@font-face {
  font-family: montserrat-medium-italic;
  src: url("/assets/fonts/Montserrat-MediumItalic.ttf");
}
@font-face {
  font-family: montserrat-light;
  src: url("/assets/fonts/Montserrat-Light.ttf");
}
.auth .auth-base.login .login-wrapped .login-header {
  margin-bottom: 36px;
}
.auth .auth-base.login .login-wrapped .login-content .login-form .form-item {
  margin-bottom: 24px;
}
.auth .auth-base.login .login-wrapped .login-content .login-form .form-item:last-child {
  margin-bottom: 0;
}
.auth .auth-base.login .login-wrapped .login-content .login-form .form-item .message-login {
  margin-top: 5px;
}
.auth .auth-base.login .login-wrapped .login-content .login-form .form-func {
  margin-top: 33px;
}
.auth .auth-base.login .login-wrapped .login-content .login-question {
  margin-top: 20px;
}

@media (min-width: 992px) {
  .auth .auth-base.login {
    padding: 97px 494px 118px 85px;
  }
}
.auth {
  background-image: url("/assets/images/auth/bg-auth.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  font-family: daxline;
  min-height: 100vh;
}
.auth:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.auth .auth-wrapped {
  position: relative;
  max-width: 902px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 95px;
}
.auth .back-home {
  display: table;
  margin-bottom: 50px;
}
.auth .back-home .back-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: unset;
  color: #0af46d;
  font-family: montserrat-bold;
  font-size: 17px;
  border-bottom: 1px solid #0af46d;
  text-decoration: none;
  line-height: normal;
}
.auth .auth-logo {
  text-align: center;
}
.auth .auth-logo .auth-website {
  color: #000000;
  font-size: 18px;
  line-height: normal;
  letter-spacing: 5.1px;
  font-family: montserrat-light;
}
.auth .auth-base {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 15px;
  position: relative;
  box-shadow: 0px 0px 27px 10px rgba(0, 255, 96, 0.34);
}
.auth .auth-base .base-wrapped {
  position: relative;
}
.auth .auth-base .base-header {
  margin-bottom: 7px;
}
.auth .auth-base .base-header .header-title {
  color: #000000;
  font-size: 30px;
  font-family: daxline-bold;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
}
.auth .auth-base .base-content .base-form {
  margin: 0 -5px;
  position: relative;
}
.auth .auth-base .base-content .base-form.register-form .form-reverse {
  display: flex;
  flex-direction: column-reverse;
}
.auth .auth-base .base-content .base-form .form-box .react-datepicker-wrapper .react-datepicker__input-container input {
  border-color: #dedeed;
}
.auth .auth-base .base-content .base-form .form-item {
  margin-bottom: 5.6px;
  padding: 0 5px;
}
.auth .auth-base .base-content .base-form .form-item .item-label {
  margin-left: 2px;
  margin-bottom: 2px;
}
.auth .auth-base .base-content .base-form .form-item .item-val {
  border-color: #dedeed;
}
.auth .auth-base .base-content .base-form .form-item .suggestion {
  position: relative;
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translate(0, -50%);
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped.has-error .suggestion-desc {
  transform: scale(1);
  filter: drop-shadow(0 0 5px rgba(255, 0, 0, 0.5));
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped.has-error .suggestion-desc .text {
  color: #ff0055;
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped .suggestion-icon {
  color: #00a2ff;
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped .suggestion-icon:hover ~ .suggestion-desc {
  transform: scale(1);
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped .suggestion-icon i {
  display: block;
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped .suggestion-desc {
  position: absolute;
  top: 0;
  left: 160%;
  transform: scale(0);
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.5));
  min-width: 200px;
  transform-origin: left 10px;
  transition: all 0.35s ease;
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped .suggestion-desc:before {
  content: "";
  position: absolute;
  top: 4px;
  left: -8px;
  transform: translate(0, 0);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 9px solid #ffffff;
  z-index: -1;
}
.auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped .suggestion-desc .text {
  font-size: 13.3px;
  background: #ffffff;
  position: relative;
  padding: 5px;
  border-radius: 3px;
}
.auth .auth-base .base-content .base-form .form-term {
  margin-left: 5px;
}
.auth .auth-base .base-content .base-form .form-term .term-tick {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin-top: 5px;
}
.auth .auth-base .base-content .base-form .form-term .term-tick .confirm-tick {
  background: #cdcde4;
  border-radius: 2px;
  border: 1px solid #b3b3bc;
  margin-top: 2px;
  cursor: pointer;
}
.auth .auth-base .base-content .base-form .form-term .term-tick .confirm-tick.active i {
  transform: scale(1);
}
.auth .auth-base .base-content .base-form .form-term .term-tick .confirm-tick i {
  transition: all 0.35s ease;
  transform: scale(0);
  font-size: 11px;
  display: block;
}
.auth .auth-base .base-content .base-form .form-term .term-tick .text {
  font-size: 12px;
}
.auth .auth-base .base-content .base-form .form-term .term-tick .text a {
  color: #00a2ff;
  margin: 0 3px;
  text-decoration: underline;
}
.auth .auth-base .base-content .base-form .form-term .term-tick .text span {
  cursor: pointer;
}
.auth .auth-base .base-content .base-form .form-term .term-tick .text span:first-child {
  padding-left: 11px;
}
.auth .auth-base .base-content .base-form .form-func {
  margin-top: 17px;
  text-align: center;
}
.auth .auth-base .base-content .base-form .form-func .btn-create {
  font-size: 13px;
  padding: 5px 33px;
  text-transform: uppercase;
}
.auth .auth-base .base-content .base-question {
  text-align: center;
  font-size: 13.5px;
}
.auth .auth-base .base-content .base-question.register-question {
  margin-top: 12px;
}
.auth .auth-base .base-content .base-question a {
  margin-left: 25px;
  text-decoration: underline;
}

.modal .modal-auth .modal-content .modal-header {
  padding-top: 35px;
  border-bottom: 0;
}
.modal .modal-auth .modal-content .modal-header .modal-title {
  font-family: daxline;
  font-size: 14.6px;
  text-align: center;
  flex-basis: 100%;
}
.modal .modal-auth .modal-content .modal-body {
  padding-bottom: 24px;
}
.modal .modal-auth .modal-content .modal-body #otp {
  display: table;
  margin: 0 auto;
}
.modal .modal-auth .modal-content .modal-body #otp .otp-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
}
.modal .modal-auth .modal-content .modal-body #otp .otp-item:not(:last-child) .otp-resend:after {
  content: "";
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translate(0, -50%);
  height: 2px;
  width: 5px;
  background: #cdcde4;
}
.modal .modal-auth .modal-content .modal-body #otp .otp-item .otp-resend {
  flex-grow: 1;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
}
.modal .modal-auth .modal-content .modal-body #otp .otp-item .otp-resend.active .btn-resend {
  color: #00a2ff;
  border-color: #00a2ff;
}
.modal .modal-auth .modal-content .modal-body #otp .otp-item .otp-resend .btn-resend {
  font-size: 13.3px;
  background-color: transparent;
  color: #000000;
  border: none;
  border-bottom: 1px solid #000000;
  border-radius: 0;
  padding: 0;
  font-family: daxline;
}
.modal .modal-auth .modal-content .modal-body #otp .otp-item .otp-resend .btn-resend:hover {
  color: #00a2ff;
  border-color: #00a2ff;
}
.modal .modal-auth .modal-content .modal-body #otp .otp-item .otp-resend .btn-resend .resend-time {
  margin-left: 3px;
}
.modal .modal-auth .modal-content .modal-body #otp .otp-item .item-val {
  padding: 5px;
  text-align: center;
  width: auto;
}
.modal .modal-auth .modal-content .modal-body .opt-message {
  font-family: daxline;
  margin-top: 5px;
}
.modal .modal-auth .modal-content .modal-body .next-step {
  margin-top: 26px;
  text-align: center;
}
.modal .modal-otp {
  margin-top: 194px;
}
.modal .modal-alert {
  margin-top: 147px;
}
.modal .modal-alert .modal-content .modal-body {
  padding-top: 10px;
}
.modal .modal-alert .modal-content .modal-body .alert-content {
  text-align: center;
}
.modal .modal-alert .modal-content .modal-body .alert-content .alert-access {
  margin-top: 35px;
}

@media (min-width: 992px) {
  .auth .auth-logo {
    position: absolute;
    top: 158px;
    right: 34px;
    transform: translate(0, 0);
  }
  .auth .auth-logo .auth-website {
    color: #ffffff;
  }
  .auth .auth-base {
    padding: 36px 460px 29px 52px;
    background: rgba(0, 0, 0, 0.6);
  }
  .auth .auth-base:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(0, 0);
    height: 100%;
    width: 100%;
    background: #ffffff;
    clip-path: polygon(0 0%, 43% 0%, 100% 98%, 100% 100%, 0 100%);
    border-radius: 5px;
  }
  .auth .auth-base .base-content .base-form .form-box .react-datepicker-wrapper .react-datepicker__input-container input {
    padding: 3.5px 10px;
  }
  .auth .auth-base .base-content .base-form .form-item .item-label {
    font-size: 13.3px;
  }
  .auth .auth-base .base-content .base-form .form-item .item-val {
    padding: 3.5px 10px;
  }
}
@media (max-width: 991px) {
  .auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped .suggestion-desc {
    left: unset;
    right: 0;
    top: 25px;
    transform-origin: right 0px;
  }
  .auth .auth-base .base-content .base-form .form-item .suggestion .suggestion-wrapped .suggestion-desc:before {
    top: -8px;
    right: 3px;
    left: unset;
    border-top: unset;
    border-bottom: 9px solid #ffffff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
  .auth .auth-wrapped {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 50px;
  }
  .auth .auth-logo {
    margin-bottom: 10px;
  }
}
.auth .auth-base.reset-password .reset-password__wrapped {
  max-width: 450px;
  margin: 0 auto;
}
.auth .auth-base.reset-password .reset-password__wrapped .reset-password__header {
  margin-bottom: 36px;
}
.auth .auth-base.reset-password .reset-password__wrapped .reset-password__content .reset-password__form .form-item {
  margin-bottom: 24px;
}
.auth .auth-base.reset-password .reset-password__wrapped .reset-password__content .reset-password__form .form-item:last-child {
  margin-bottom: 0;
}
.auth .auth-base.reset-password .reset-password__wrapped .reset-password__content .reset-password__form .form-item .message-login {
  margin-top: 5px;
}
.auth .auth-base.reset-password .reset-password__wrapped .reset-password__content .reset-password__form .form-func {
  margin-top: 33px;
}
.auth .auth-base.reset-password .reset-password__wrapped .reset-password__content .reset-password__question {
  margin-top: 20px;
}

@media (min-width: 992px) {
  .auth .auth-base.reset-password {
    min-height: 542px;
    padding: 97px 494px 118px 85px;
  }
}