$widthSidebar: 275px;

@keyframes loading-animate {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: 200%;
  }
}

.loading-animate {
  background: linear-gradient(125.75deg, white 0%, #dee4f0 33%, white 66%, #dee4f0 100%);
  background-size: 200% 100%;
  animation-name: loading-animate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border-radius: 8px;
  max-width: 400px;
  width: 90vw;
  padding-bottom: 141.453%;
  font-size: 0;
}

@keyframes waiting {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.waiting {
  @include displayFlex(center, center, wrap);
  @include positionTopLeft(absolute, 50%, 50%, translate(-50%, -50%));
  width: 110%;
  height: 110%;
  background: rgba(202, 202, 202, .5);
  border-radius: 5px;
  z-index: 1;

  .waiting-wrapped {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    .wait-item {
      position: absolute;
      width: 6px;
      height: 6px;
      background: #6dbe65;
      border-radius: 50%;
      animation: waiting 1.2s linear infinite;

      &:nth-child(1) {
        animation-delay: 0s;
        top: 37px;
        left: 66px;
      }

      &:nth-child(2) {
        animation-delay: -0.1s;
        top: 22px;
        left: 62px;
      }

      &:nth-child(3) {
        animation-delay: -0.2s;
        top: 11px;
        left: 52px;
      }

      &:nth-child(4) {
        animation-delay: -0.3s;
        top: 7px;
        left: 37px;
      }

      &:nth-child(5) {
        animation-delay: -0.4s;
        top: 11px;
        left: 22px;
      }

      &:nth-child(6) {
        animation-delay: -0.5s;
        top: 22px;
        left: 11px;
      }

      &:nth-child(7) {
        animation-delay: -0.6s;
        top: 37px;
        left: 7px;
      }

      &:nth-child(8) {
        animation-delay: -0.7s;
        top: 52px;
        left: 11px;
      }

      &:nth-child(9) {
        animation-delay: -0.8s;
        top: 62px;
        left: 22px;
      }

      &:nth-child(10) {
        animation-delay: -0.9s;
        top: 66px;
        left: 37px;
      }

      &:nth-child(11) {
        animation-delay: -1s;
        top: 62px;
        left: 52px;
      }

      &:nth-child(12) {
        animation-delay: -1.1s;
        top: 52px;
        left: 62px;
      }
    }
  }
}

body {
  font-family: montserrat;
  user-select: none;
}

*,
button,
.btn {

  &:hover,
  &:focus,
  &:active,
  &:visited {
    outline: none;
    box-shadow: none;
  }

  &.btn-copy {
    &.disabled {
      pointer-events: none;
      opacity: .65;
    }
  }
}

p {
  margin-bottom: 0;
}

input,
select {
  background: $whiteColor;
  border: 1px solid $lightBlueColor;
  border-radius: 3px;
  padding: 7px 15px;
  font-size: 13.2px;
  width: 100%;

  &:disabled {
    background: $inputColor;
  }
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  &.error-500 {
    min-height: 100vh;

    .back-home {
      margin-right: 40px;
    }
  }

  .back-home {
    display: block;
    margin-top: -60px;
  }
}

.red {
  color: $redColor;
}

.text-color {
  @include fontSize13-3;
}

.btn {
  &.orange {
    background: $orangeColor;
    color: $whiteColor;
  }

  &.green {
    background: $greenColor;
    color: $whiteColor;
  }

  &.blue {
    background: $blueColor;
    color: $whiteColor;
  }

  &.btn-func {
    background: $greenBtnColor;
    color: $whiteColor;
    border-radius: 50px;
    padding: 5px 50px;
    font-size: 15px;
    box-shadow: 0px 5px 20px 0 rgba(13, 191, 130, .55);

    &.red {
      background: $redBtnColor;
      box-shadow: 0px 5px 20px 0 rgba(237, 28, 36, .55);
    }
  }
}

.item-verify {
  margin-top: 5px;
}

.text-verify {
  display: block;
  line-height: normal;

  .verify-icon {
    border-radius: 100%;
    display: inline-block;
    text-align: center;
    line-height: normal;
    margin-right: 5px;

    i {
      display: block;
      @include fontSize16;
    }
  }
}

.form-half {
  @include displayFlex(flex-start, flex-start, wrap);

  .form-item {
    flex-basis: 50%;
  }
}

.react-datepicker-wrapper {
  display: block;
}

.react-datepicker-popper {
  select {
    padding: 5px;
  }
}

.price-box {
  padding: 1px;
  border: 1px solid $lightBlueColor;
  border-radius: 3px;

  .price-number {
    position: relative;

    .price-val {
      padding: 3.5px 60px 3.5px 15px;
      border: none;
      font-size: inherit;
      border-radius: 3px;
      width: 100%;
    }

    .price-type {
      background: $purpleDarkColor;
      color: $whiteColor;
      border-radius: 3px;
      @include positionTopRight(absolute, 50%, 0, translate(0, -50%));
      height: calc(100% - 1px);
      padding: 5px 8px;
      @include fontSize14;
      display: flex;
      align-items: center;
    }
  }
}

.table-responsive {
  position: relative;

  .waiting {
    width: 100%;
    height: 100%;
  }
}

.table {
  margin-bottom: 0;
}

.pagi {
  @include displayFlex(center, center, wrap);
  margin-top: 10px;
  margin-bottom: 10px;

  .pagi-item {
    display: inline-block;
    margin: 0 2px;

    &.active {
      .pagi-link {
        background-color: $theadHistoryColor;
        color: $whiteColor;
      }
    }

    &.disable {
      pointer-events: none;

      .pagi-link {
        cursor: default;
        pointer-events: none;

        &:hover {
          background-color: transparent;
          color: $blackColor;
        }
      }
    }

    &.pagi-dots {
      .pagi-link {
        cursor: default;
        border: none;

        &:hover {
          background: transparent;
          color: $blackColor;
        }
      }
    }

    &.pagi-prev {
      margin-right: 40px
    }

    &.pagi-next {
      margin-left: 40px;
    }

    .pagi-link {
      display: block;
      cursor: pointer;
      padding: 3px 6px;
      font-size: 13px;
      border: 1px solid $theadHistoryColor;
      min-width: 30px;
      text-align: center;
      border-radius: 3px;

      &:hover {
        background-color: $theadHistoryColor;
        color: $whiteColor;
      }

      i {
        line-height: unset;
      }
    }
  }
}

.control-tab {
  @include displayFlex(center, flex-start, wrap);
  margin-bottom: 15px;

  .control-item {
    position: relative;
    @include fontSize14-5;
    padding-left: 26px;
    cursor: pointer;
    line-height: normal;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0 !important;
    }

    &.active {
      .dot {
        &:after {
          content: "";
          @include positionTopLeft(absolute, 50%, 50%, translate(-50%, -50%));
          width: 70%;
          height: 70%;
          background: $greenColor;
          border-radius: 100%;
        }
      }
    }

    .dot {
      @include positionTopLeft(absolute, 50%, 0, translate(0, -50%));
      width: 13px;
      height: 13px;
      background: $grayColor;
      border-radius: 100%;
    }
  }
}

.body-wrapped {
  display: flex;
  width: 100%;

  i {
    display: block;
  }

  .wrapped-left {
    @include positionTopLeft(fixed, 0, 0, translate(0, 0));
    width: 100%;
    background: $linearSideBar;
    max-width: $widthSidebar;
    overflow-y: auto;
    height: 100%;
    transition: all .3s cubic-bezier(0.945, 0.020, 0.270, 0.665);
    z-index: 999;
    box-shadow: 15px 15px 25px 0px rgba(6, 7, 50, .3);

    &::-webkit-scrollbar {
      width: 0;
    }

    &::-webkit-scrollbar-track {
      background: #cdcdcd;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    &:before {
      content: "";
      @include positionTopLeft(absolute, 0, 0, unset);
      width: 100%;
      height: 100%;
      @include bgImage($bgSidebar, no-repeat, 100%, bottom);
      z-index: -1;
    }

    #sidebar {
      padding: 20px;
      padding-left: 23px;
      padding-right: 25px;
      position: relative;

      .sidebar-wrapped {
        margin-bottom: 20px;

        .sidebar-header {
          margin-top: 30px;
          margin-left: 27px;

          .close-sidebar {
            display: none;
            margin: 5px 5px 5px auto;
            color: $whiteColor;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;

            i {
              font-size: 35px;
            }
          }
        }

        .sidebar-list {
          margin-top: 40px;
          padding-top: 10px;
          padding-bottom: 70px;
          position: relative;
          overflow: hidden;

          &:before,
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            left: 50%;
            transform: translateX(-50%);
            height: 25px;
            background: rgba(0, 0, 0, 0.2);
            filter: blur(5px);
            border-radius: 100%;
          }

          &:before {
            top: -15px;
          }

          &:after {
            bottom: -15px;
          }

          .sidebar-content {
            @include unstyle;

            .sidebar-item {
              margin-bottom: 8px;

              .sidebar-link {
                position: relative;
                padding: 18px 10px;
                padding-left: 55px;
                display: block;
                font-size: 13px;
                text-transform: uppercase;
                text-decoration: none;
                color: $whiteColor;
                transition: all .5s ease;
                z-index: 0;

                &:after {
                  content: '';
                  @include positionTopLeft(absolute, 50%, 50%, translate(-50%, -50%));
                  width: 100%;
                  height: 100%;
                  box-shadow: 0 0 0 0 inset transparent;
                  border-radius: 50px;
                  z-index: -1;
                  transition: all .35s ease;
                }

                &:hover,
                &.active {
                  background: $greenDarkColor;
                  border-radius: 50px;

                  &:after {
                    box-shadow: 0px 0px 16px 7px inset rgba(25, 27, 47, 0.31);
                  }
                }

                .sidebar-icon {
                  @include positionTopLeft(absolute, 50%, 15px, translate(0, -50%));
                }
              }
            }
          }
        }
      }

      .copy-right {
        margin-left: 8px;
        text-align: center;

        .text {
          margin-bottom: 0;
          @include fontSize14;
          letter-spacing: 3px;
          word-spacing: 2px;
          text-align: center;
          line-height: 25px;
          color: $whiteColor;
        }
      }
    }
  }

  .wrapped-right {
    min-height: 100vh;
    width: 100%;

    &:not(.dashboad) {
      .main {
        min-height: calc(100vh - 110px);

        .section {
          &.section-error {
            min-height: calc(100vh - 90px - 110px);
          }

          &.section-border-top {
            padding: 50px 36px;
          }

          .section-header {
            .header-desc {
              @include fontSize13-3;
            }
          }
        }

        .table {
          border: 1px solid #b5bdd8;

          td,
          th {
            min-width: 140px;
            vertical-align: middle;
            white-space: nowrap;
          }

          thead {
            tr {
              th {
                background: $theadHistoryColor;
                @include title($whiteColor, 14px, montserrat-semibold, 0, 19px, unset);
                border: none;
              }
            }
          }

          tbody {
            tr {
              border-top: 4px solid $whiteColor;
              border-bottom: 4px solid $whiteColor;

              &:hover {
                background-color: #daf4ef;
              }

              &.no-data {
                border: none;
                cursor: default !important;

                &:hover {
                  background-color: transparent;
                }

                td {

                  &:first-child {
                    &:before {
                      content: unset;
                    }
                  }

                  &:after {
                    content: unset;
                  }

                  .none {
                    @include displayFlex(center, center, wrap);
                    min-height: 300px;
                  }
                }
              }

              td {
                @include fontSize13-3;
                padding: 14px 18px;
                position: relative;
                border: none;

                &:first-child {
                  &:before {
                    content: "";
                    @include positionTopLeft(absolute, 0, 0, translate(0, 0));
                    width: 4px;
                    height: 100%;
                    background: $theadHistoryColor;
                  }
                }

                &:after {
                  content: "";
                  @include positionTopLeft(absolute, 0, 0, translate(0, 0));
                  width: 100%;
                  height: 100%;
                  border: solid #b5bdd8;
                  border-width: 1px 0 1px 0;
                }

                .action {
                  padding: 2px 8px;
                  color: $whiteColor;
                  display: block;
                  line-height: normal;
                  text-align: center;
                  position: relative;
                  z-index: 1;
                  border: none;
                  width: 100%;
                }

                &.pending {
                  .status {
                    color: $orangeColor;
                  }

                  .action {
                    background-color: $greenBtnColor;
                  }
                }

                &.active {
                  .status {
                    color: $greenBtnColor;
                  }

                  .action {
                    background-color: $orangeColor;
                  }
                }

                &.expire {
                  .status {
                    color: $redDarkColor;
                  }

                  .action {
                    background-color: $orangeColor;
                  }
                }

                &.waitFinish {
                  .status {
                    color: $theadHistoryColor;
                  }

                  .action {
                    background-color: $theadHistoryColor;
                  }
                }

                &.end {
                  .status {
                    color: $theadHistoryColor;
                  }

                  .action {
                    background-color: $redDarkColor;
                  }
                }
              }
            }
          }
        }
      }
    }

    .header {
      @include bgImage($bgHeader, no-repeat, cover, center);

      .header-wrapped {
        padding: 30px 0 39px;
        position: relative;
        z-index: 1;

        .header-mobile {
          display: none;

          .header-right {
            .show-sidebar {
              cursor: pointer;
              color: $whiteColor;

              i {
                font-size: 40px;
              }

              &.active {
                opacity: 0;
              }
            }
          }
        }

        .header-desktop {
          .header-nav {
            margin: 0 26px 0 20px;

            .nav-wrapped {
              @include displayFlex(center, space-between, wrap);

              .nav-left {
                margin-right: 9px;

                .wallet {
                  @include displayFlex(center, center, wrap);

                  .wallet-item {
                    @include displayFlex(center, normal, nowrap);
                    position: relative;
                    padding-left: 53px;
                    padding-right: 10px;
                    min-width: 167px;
                    margin-right: 10px;
                    background: #303a51;
                    border-radius: 50px;

                    &:last-child {
                      margin-right: 0;
                    }

                    &:before {
                      content: '';
                      @include positionTopLeft(absolute, 50%, 50%, translate(-50%, -50%));
                      width: 100%;
                      height: 100%;
                      z-index: -1;
                      border: 3px solid #22d167;
                      border-radius: 50px;
                      filter: blur(5px);
                      transition: all .5s ease;
                      opacity: 0;
                    }

                    &:after {
                      content: "";
                      @include positionTopLeft(absolute, 50%, 50%, translate(-50%, -50%));
                      width: 180px;
                      height: 51px;
                      background: $linearToBottom;
                      border-radius: 50px;
                      z-index: -1;
                      opacity: 0;
                      transition: all .5s ease;
                    }

                    &:hover {
                      &:before {
                        opacity: 1;
                      }

                      &:after {
                        opacity: .4;
                      }
                    }

                    .wallet-icon {
                      @include positionTopLeft(absolute, 50%, 10px, translate(0, -50%));

                      .icon-name {
                        @include positionTopLeft(absolute, 11px, 6px, translate(0, 0));
                        font-size: 12px;
                      }
                    }

                    .wallet-content {
                      p {
                        margin-bottom: 0px;
                        font-size: 13.5px;
                        font-family: daxline;
                        color: $whiteColor;
                      }

                      .wallet-title {
                        margin-top: 3px;
                        margin-bottom: -2px;
                      }
                    }
                  }
                }
              }

              .nav-right {
                @include displayFlex(center, center, wrap);

                .border-linear {
                  background: $linearToBottom;
                  padding: 1px;
                  border-radius: 50px;
                }

                .header-agency {
                  .agency-box {
                    position: relative;

                    .link-agency {
                      border: none;
                      border-radius: 50px;
                      height: 34px;
                      width: 236px;
                      padding: 5px 65px 5px 15px;
                      text-overflow: ellipsis;
                      color: $blackColor;
                      font-size: 13.5px;

                      &::placeholder {
                        font-family: montserrat-italic;
                      }
                    }

                    .btn-copy {
                      @include positionTopRight(absolute, 50%, 3px, translate(0, -50%));
                      color: $whiteColor;
                      height: calc(100% - 7px);
                      min-width: 55px;
                      border-radius: 50px;
                      border: none;
                      background: $linearToBottom;
                      padding: 0px 6px;
                      font-size: 13px;
                      font-family: montserrat;
                    }
                  }
                }

                .btn-showNotifi,
                .btn-signOut {
                  padding: 6px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 100%;
                  background: #fff;

                  i {
                    background: linear-gradient(to bottom, #10bdd9 0%, #0be995 100%);
                    font-size: 20px;
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                  }
                }

                .header-notification {
                  margin-left: 6px;


                }

                .header-welcome {
                  margin-left: 5px;

                  .account {
                    @include displayFlex(center, space-between, nowrap);
                    position: relative;
                    border-radius: 50px;
                    background: $whiteColor;
                    min-width: 158px;

                    .account-name {
                      padding-right: 18px;
                      padding-left: 16px;
                      font-size: 12.5px;
                      font-family: montserrat-medium;
                    }

                    .account-avatar {
                      width: 34px;
                      height: 34px;
                      border-radius: 100%;
                      background: $whiteColor;
                      border-left: 1px solid #000;
                      overflow: hidden;

                      .avatar-img {
                        width: 100%;
                      }
                    }

                    .account-status {
                      @include positionTopRight(absolute, 3px, -3px, translate(0, 0));
                      background: $linearToBottom;
                      border-radius: 100%;

                      i {
                        font-size: 13px;
                        color: $whiteColor;
                      }
                    }
                  }
                }

                .header-signOut {
                  margin-left: 10px;
                }
              }
            }
          }
        }
      }
    }

    .main {
      padding: 45px 50px;
      background-color: #e6f2ff;
      min-height: calc(100vh - 165px);

      .section {
        .section-header {
          padding-left: 100px;
          position: relative;
          min-height: 57px;

          .header-icon {
            @include positionTopLeft(absolute, 0, 3px, translate(0, 0));
            min-width: 64px;
            text-align: center;
            padding-bottom: 16px;

            &:after {
              content: "";
              @include positionBottomLeft(absolute, 0, 50%, translate(-50%, 0));
              height: 5px;
              width: 100%;
              background: $greenColor;
            }
          }

          .header-title {
            @include title($headerTitleColor, 25px, montserrat-semibold, 0 0 7px, 0, uppercase);
          }

          .header-desc {
            @include title($headerTitleColor, 13.5px, montserrat-italic, 0 0 0 2px, 0, none);
          }
        }

        .section-content {
          .table-func {
            @include displayFlex(center, space-between, wrap);
            margin-top: 34px;

            .config-pageSize {
              select {
                padding: 7px 15px 7px 10px;
              }
            }
          }

          &.cooperate-content {
            margin: 30px auto 0;
            max-width: 984px;

            .table-cooperate {

              th,
              td {
                text-align: center;
                font-weight: normal;
              }

              thead {
                tr {
                  th {
                    background: $purpleDarkColor;
                    white-space: nowrap;
                    padding: 20px 26px;
                    color: #ffffff;
                    @include fontSize14;
                    border: none;

                    &:first-child {
                      width: 50px;
                    }

                    &:nth-child(2) {
                      width: 369px;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    border: solid $whiteColor;
                    border-width: 3px 5px;
                    color: #404463;
                    background: #e6f2ff;
                    vertical-align: middle;
                    @include fontSize14;
                    padding: 8px 9px;

                    &:first-child {
                      border-left: 0;
                    }

                    &:last-child {
                      border-right: 0;
                    }
                  }
                }
              }
            }

            .cooperate-note {
              margin: 37px 0 15px;
              @include fontSize14-5;

              .note-text {
                margin-bottom: 0;

                b {
                  margin-right: 28px;
                }
              }

              .note-list {
                @include unstyle;
                margin: 3px 5px;

                .note-item {
                  margin-bottom: 4px;
                }
              }
            }
          }

          &.agency-content {
            margin-top: 30px;
            @include bgImage($bgMapWorld, no-repeat, contain, 70px);

            .agency-list {
              max-width: 812px;
              margin: 0 auto;

              .agency-item {
                border-left: 3px solid $greenColor;
                padding-left: 18px;
                margin-bottom: 25px;

                .item-header {
                  .item-title {
                    font-size: 17.1px;
                    margin-bottom: 4px;
                    @include fontMontserratSemibold;
                  }
                }

                .item-content {
                  p {
                    text-indent: 35px;
                  }

                  .subtitle {
                    font-size: 15.4px;
                    margin-bottom: 2px;
                    font-family: montserrat-bold;
                  }

                  .text {
                    font-size: 14.6px;
                    margin-bottom: 4px;
                    line-height: 23px;
                  }
                }
              }

            }
          }
        }

        &.section-border-top {
          @include section(#fff, 45px 25px, 0 0 15px, 0);
          position: relative;
          box-shadow: 0px 0px 35px 0px rgba(49, 61, 87, .15);

          &:before {
            content: '';
            @include positionTopLeft(absolute, 0, 0, translate(0, 0));
            width: 100%;
            height: 5px;
            background: $greenColor;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.section-statistic {
          position: relative;
          z-index: 1;

          .section-statistic-wrapped {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;

            .statistic-col {
              padding: 0 15px;
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            .statistic-box {
              &:hover {
                .statistic-item {
                  top: -11px;
                }
              }
            }

            .statistic-item {
              position: relative;
              top: 0;
              width: 100%;
              padding: 12px;
              padding-left: 72px;
              background: $whiteColor;
              min-height: 155px;
              border-radius: 5px;
              z-index: 0;
              overflow: hidden;
              transition: all .35s ease;


              &:before {
                content: "";
                @include positionTopLeft(absolute, 0, 0, translate(0, 0));
                width: 100%;
                height: 100%;
                z-index: -1;
                background-size: 100%;
                background-repeat: no-repeat;
                background-position: bottom;
              }

              &.total-invest {
                &:before {
                  background-image: $bgStatisticPink;
                }
              }

              &.total-profit {
                &:before {
                  background-image: $bgStatisticGreen;
                }
              }

              &.total-agency {
                &:before {
                  background-image: $bgStatisticBlue;
                }
              }

              &.total-turnover {
                &:before {
                  background-image: $bgStatisticOrange;
                }
              }

              .statistic-icon {
                @include positionTopLeft(absolute, 12px, 10px, translate(0, 0))
              }

              .statistic-content {
                position: relative;

                .statistic-title {
                  font-family: daxline;
                  font-size: 16.6px;
                  text-transform: uppercase;
                  margin-bottom: 88px;
                  text-align: right;
                }

                .statistic-number {
                  font-family: digital-italic;
                  font-size: 25px;
                  line-height: normal;
                  display: table;
                  margin-left: auto;
                  margin-right: 18px;
                  letter-spacing: 1px;
                  color: $whiteColor;
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        &.section-position {
          margin: 20px 0;

          .section-position-wrapped {
            position: relative;

            .position-list {
              position: relative;
              z-index: 1;

              .position-item {
                position: relative;
                display: flex;
                padding: 24px 19px;
                padding-right: 5px;
                max-width: 313px;
                background: $whiteColor;
                border-radius: 0 10px 10px 0;
                margin-left: 9px;
                margin-bottom: 16px;
                z-index: 0;
                overflow: hidden;
                box-shadow: 0px 0px 15px 0px rgba(6, 7, 50, .1);

                &:last-child {
                  margin-bottom: 0;
                }

                &.active {
                  margin-left: 0;
                  margin-bottom: 23px;
                  box-shadow: 10px 10px 20px 0px rgba(39, 198, 103, .39);

                  &:after {
                    width: 100%;
                    background: linear-gradient(to right, #25a991 0%, #27c568 100%);
                  }

                  .position-status {
                    .tick {
                      background: $whiteColor;

                      i {
                        display: block;
                      }
                    }
                  }

                  .position-name {
                    color: $whiteColor;
                    text-transform: uppercase;
                  }
                }

                &:after {
                  content: "";
                  @include positionTopLeft(absolute, 0, 0, translate(0, 0));
                  width: 3px;
                  height: 100%;
                  background: $greenColor;
                  z-index: -1;
                }

                .position-status {
                  margin-right: 15px;

                  .tick {
                    display: block;
                    background: #c1d3e7;
                    border-radius: 100%;
                    padding: 2px;
                    position: relative;
                    width: 19px;
                    height: 19px;
                    color: $greenColor;

                    i {
                      display: none;
                    }
                  }
                }
              }
            }

            .position-img {
              @include positionTopRight(absolute, 50%, 32px, translate(0, -50%));
            }
          }
        }

        &.section-cooperate {
          padding-bottom: 35px;
        }

        &.section-agency {
          padding-bottom: 65px;
        }
      }
    }
  }

  .wrapped-overlay {
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 998;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
}

@media (min-width:1200px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          .section-content {
            &.cooperate-content {
              margin-top: 72px;
            }

            &.agency-content {
              margin-top: 58px;
            }
          }

          &.section-position {
            margin-top: 75px;
            margin-bottom: 88px;

            .section-position-wrapped {
              padding-left: 54px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .body-wrapped {
    .wrapped-right {
      width: calc(100% - #{$widthSidebar});
      margin-left: auto;

      &.dashboad {
        .header {
          .header-wrapped {
            padding: 30px 0 94px;
          }
        }

        .main {
          padding: 15px 50px 45px;
        }
      }

      .main {
        .section {
          &.section-border-top {
            margin-bottom: 50px;
          }

          &.section-statistic {
            margin-top: -65px;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          &.section-statistic {
            .section-statistic-wrapped {
              .statistic-col {
                flex-basis: 50%;
                max-width: 50%;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1510px) {
  .body-wrapped {
    .wrapped-right {
      &:not(.dashboad) {
        .main {
          .table {

            td,
            th {
              white-space: normal;
            }
          }
        }
      }

      .main {
        .section {
          &.section-statistic {
            .section-statistic-wrapped {
              .statistic-col {
                flex-basis: 25%;
                max-width: 25%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1379px) {
  .body-wrapped {
    .wrapped-right {
      .header {
        .header-wrapped {
          .header-desktop {
            .header-nav {
              .nav-wrapped {
                justify-content: center;

                .nav-left {
                  .wallet {
                    .wallet-item {
                      margin-bottom: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .main {
        .section {

          .section-content {
            &.agency-content {
              background-size: cover;
              background-position: center;
            }
          }

          &.section-position {
            .section-position-wrapped {
              .position-img {
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .body-wrapped {
    .wrapped-right {
      .main {
        .section {
          &.section-position {
            .section-position-wrapped {
              .position-list {
                max-width: 550px;
                margin: 0 auto;

                .position-item {
                  max-width: unset;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:991px) {
  .body-wrapped {
    .wrapped-left {
      left: calc(-#{$widthSidebar} - 100px);

      &.active {
        left: 0;
      }

      #sidebar {
        .sidebar-wrapped {
          .sidebar-header {
            .close-sidebar {
              display: table;
            }
          }
        }
      }
    }

    .wrapped-right {
      .header {
        height: auto;

        .header-wrapped {
          padding-bottom: 20px;

          .header-mobile {
            @include displayFlex(center, space-between, nowrap);
            margin-bottom: 15px;
          }
        }
      }

      .main {
        padding: 15px;
      }
    }

    .wrapped-overlay {
      &.active {
        display: block;
        opacity: 1;
      }
    }
  }
}

@media (max-width: 767px) {
  .body-wrapped {
    .wrapped-right {
      .header {
        .header-wrapped {
          .header-desktop {
            .header-nav {
              margin: 0;
            }
          }
        }
      }

      .main {
        .section {
          .section-header {
            .header-title {
              font-size: 20px;
            }
          }

          &.section-statistic {
            .section-statistic-wrapped {
              justify-content: center;

              .statistic-col {
                flex-basis: 100%;
                max-width: 365px;
              }
            }
          }

          &.section-border-top {
            padding: 20px 15px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .body-wrapped {
    .wrapped-right {
      .header {
        .header-wrapped {
          .header-desktop {
            .header-nav {
              .nav-wrapped {
                .nav-right {
                  .header-agency {
                    flex-basis: 100%;
                    margin-bottom: 15px;

                    .agency-box {
                      .link-agency {
                        width: 100%;
                      }
                    }
                  }

                  .header-notification {
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width:479px) {
  .form-half {
    .form-item {
      flex-basis: 100%;
    }
  }

  .pagi {
    .pagi-item {
      margin: 2px;

      &.pagi-prev {
        margin-right: 0;
      }

      &.pagi-next {
        margin-left: 0;
      }
    }
  }

  .body-wrapped {
    .wrapped-right {
      .header {
        .header-wrapped {
          .header-desktop {
            .header-nav {
              .nav-wrapped {
                .nav-left {
                  .wallet {
                    .wallet-item {
                      margin-left: 5px;
                      margin-right: 5px;

                      &:last-child {
                        margin-right: 5px;
                      }
                    }
                  }
                }

                .nav-right {

                  .header-notification,
                  .header-welcome,
                  .header-signOut {
                    margin-top: 5px;
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
      }

      .main {
        .section {
          .section-header {
            padding-left: 0;
            min-height: auto;
          }

          &.section-position {
            .section-position-wrapped {
              .position-list {
                .position-item {
                  .position-name {
                    @include fontSize14;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}